@import "../../../styles/breakpoints";

.WelcomeSection {
  display: flex;
  width: 100%;
  margin: auto;
  /* margin-top: 200px; */
  /* justify-content: space-between; */
  align-items: center;
  position: relative;
  column-gap: 60px;
  @include max-md {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.headerCell {
  padding: 0 !important;
  border: none !important;
}

.headerCell-value {
  margin: 0 2px 0px 0px;
  align-items: center;
  background: #f6f8fe;
  padding: 16px 20px;
  white-space: nowrap;

  color: #101828;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
  &:first-child {
    border-top-left-radius: 12px; /* Adjust the radius as needed */
    border-bottom-left-radius: 12px; /* Adjust the radius as needed */
  }
  &:last-child {
    border-top-right-radius: 12px; /* Adjust the radius as needed */
    border-bottom-right-radius: 12px; /* Adjust the radius as needed */
  }
}

// .headerCell-value:first-child{}

.table-row-no-border {
  border: none !important; /* Remove border */
}

.headerCell-bodyValue {
  border: 1px solid #f6f8fe;
  background: #fff;
  padding: 16px 20px;
  margin: 0 2px 0px 0px;
  white-space: nowrap;
  @include max-md {
    font-size: 11px;

    line-height: 20px;
  }
}

.table-head {
  margin-bottom: 10px; /* Adjust the margin as needed */
}

.table-body {
  margin-top: 10px; /* Adjust the margin as needed */
}
