@import "../../../../../../styles/breakpoints";

.JobDescriptionCard {
  // margin: 64px 32px;
  height: auto;
  flex-shrink: 0;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 20px 15px;

  @include max-md {
    padding: 24px 20px;
  }
}
.title {
  color: #101828;
   
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 16px;
  text-align: left;
  @include max-sm {
    font-size: 20px;
    line-height: 28px;
  }
}
.description {
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: justify;
  @include max-sm {
    font-size: 12px;
    line-height: 20px;
  }
}
