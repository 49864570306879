@use "../../../styles/variables" as v;
@import "../../../styles/breakpoints";
// @use "../../../styles/variables" as v;

.text {
  font-weight: 400;
  // font-family: '';
  font-family: v.$font-family, sans-serif;
  color: #0f1322;
}

.text::placeholder {
  font-size: 12px !important;
}
.text {
  &-black {
    color: black;
  }
  &-yellow {
    color: yellow;
  }
  &-white {
    color: white;
  }
  //   &-primary {
  //     color: #009633;
  //   }
  &-secondary {
    color: #75808f;
  }
  &-pink {
    color: pink;
  }
  &-alert {
    color: red;
  }
  &-gray {
    color: v.$color-TextGray;
  }
  &-dark-gray {
    color: #606060;
  }
  &-xxl {
    font-size: 64px;
  }
  &-56 {
    font-size: 56px;
  }
  &-xl {
    font-size: 48px;
    // font-family: v.$font-family-fredoka;
  }
  &-40 {
    font-size: 40px;
  }
  &-36 {
    font-size: 36px;
  }
  &-32 {
    font-size: 32px;
  }
  &-l {
    font-size: 24px;
  }
  &-m {
    font-size: 20px;
  }
  &-18 {
    font-size: 18px;
  }
  &-s {
    font-size: 16px;
  }
  &-15 {
    font-size: 15px;
  }
  &-xs {
    font-size: 14px;
  }
  &-xxs {
    font-size: 12px;
  }
  &-10 {
    font-size: 10px;
  }
  &-8 {
    font-size: 8px;
  }
  &-true {
    font-weight: 700;
  }
  &-400 {
    font-weight: 400;
  }
  &-500 {
    font-weight: 500;
  }
  &-600 {
    font-weight: 600;
  }
  &-700 {
    font-weight: 700;
    // font-family: "OpenSans-Bold";
  }
  &-800 {
    font-weight: 800;
  }
  //   &-linear-gradient {
  //     background: linear-gradient(
  //       98.29deg,
  //       #009633 -13.51%,
  //       rgba(218, 1, 79, 0.7) 48.65%,
  //       #009633 121.41%
  //     );
  //     -webkit-background-clip: text;
  //     -webkit-text-fill-color: transparent;
  //     background-clip: text;
  //     text-fill-color: transparent;

  //     text-shadow: 0px 4px 24px rgba(15, 19, 34, 0.12);
  //   }
}

.title-main {
  color: #0f1322;
  text-align: center;
  //  
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  @include max-md {
    font-size: 24px;
    line-height: 32px;
  }
}

.error {
  color: red;
  // text-align: center;
  //  
  font-size: 12px;
  font-style: normal;
  // font-weight: 500;
  // line-height: 56px;
  @include max-md {
    font-size: 12px;
    // line-height: 32px;
  }
}
