@import "../../../styles/breakpoints";

.eventCalendar {
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  // height: calc(100vh - 250px);
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 24px;

  @include max-md {
    // padding: 32px;
    // box-shadow: none;
    height: calc(100vh - 150px);
  }
  @include max-sm {
    padding: 0px;
  }
}

.calendar-body {
  display: flex;
  // overflow-y: auto;
  flex-direction: column;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  /* Hide the scrollbar for Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }
}

.eventCalendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include max-md {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 5px;
  }
}

.col-row{
display: flex;
flex-direction: row;
@include  max-md{
  flex-direction: column;
}
}

.eventCalendar-selection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 24px;

  @include max-md {
    flex-direction: column;
    align-items:flex-start;
    row-gap: 5px;;
  }
}

.eventCalendar-header-right {
  display: flex;
  column-gap: 14px;
}

.eventCalendar-selection {
  margin-top: 18px;
  margin-bottom: 20px;
  @include  max-md{
    margin-top: 0;
    
  }
}

.eventCalendar-selection-buttons {
  display: flex;
  column-gap: 5px;

  @include max-md {
  }
  @include max-sm {
  }
}

.primaryBtn {
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #fff;
  display: inline-flex;
  padding: 2px 11px;
  justify-content: center;
  align-items: center;
  color: #9fb5ff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}
.activeBtn {
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #101828;
  display: inline-flex;
  padding: 2px 11px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.activeBtn-main {
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #101828;
  display: inline-flex;
  padding: 3px 10px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  // width: 200px;
  column-gap: 8px;
  @include max-md {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.title {
  color: #101828;
 //
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  @include max-md {
    font-size: 20px;
  }
}

.calendarPicker {
  display: flex;
  align-items: center;
}

.datePicker {
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border: none !important;
}
.datePicker > div > div {
  width: 250px !important;
}
