@import "../../../styles/breakpoints";

.EmployeeRatingAndReview {
  // margin: 64px 32px;
  height: auto;
  flex-shrink: 0;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 42px 48px 28px 48px;

  @include max-md {
    padding: 24px 20px;
  }
  @include max-sm {
    // margin: 64px 20px;
  }
}

.title {
  color: #101828;
  // 
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 16px;
  @include max-md {
    // margin-top: 24px;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
  }
}

.EmployeeRatingAndReview-main {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.see-more {
  color: #1c53f4;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  @include max-md {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
}
