@import "../../../styles/breakpoints";

.HiredWorkersPage {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  width: 100%;
}

.button-container {
  display: flex;
  column-gap: 16px;
  row-gap: 16px;
  margin: 16px 0;
  max-width: 70%;
}


.primaryBtn {
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #101828;
  display: inline-flex;
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  height: 30px;
  flex-shrink: 0;
  @include max-md {
    padding: 8px 16px;
  }
}
.secondaryBtn {
  display: inline-flex;
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #fff;
  color: #9fb5ff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  flex-shrink: 0;
  height: 30px;
  @include max-md {
    padding: 8px 16px;
  }
}

.HiredWorkersPage-main {
  flex-wrap: wrap;
  row-gap: 44px;
  column-gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @include max-xl{
    grid-template-columns: repeat(3, 1fr);
  }
  @include max-lg{
    grid-template-columns: repeat(2, 1fr);
  }
  @include max-sm {
    grid-template-columns: repeat(1, 1fr);
  }
}
