@import "../../styles/breakpoints";

.JobPostingSection {
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  // height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  padding: 48px 48px 50px 48px;
  @include max-md {
    padding: 32px 32px 50px 32px;
    // height: calc(100vh - 150px);
  }
  @include max-sm {
    padding: 16px 16px 50px 16px;
  }
}

.inputField-wrap {
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  width: 100%;
  height: 40px;
  margin-bottom: 5px;
  @include max-xl {
    height: 40px;
  }
  @include max-lg {
    height: 40px;
  }
}
.btn-add-action {
  display: flex;
  flex-direction: row;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.btn-add-label {
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  margin-left: 10px;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.JobPostingSection-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include max-md {
    // padding: 32px;
    // height: calc(100vh - 150px);
  }
  @include max-sm {
    // padding: 16px;
  }
}
.JobPostingSection-head-right {
  display: flex;
  column-gap: 16px;
  @include max-md {
  }
}

.JobPostingSection-main {
  display: flex;
  padding: 40px 40px 40px 0;
  justify-content: space-between;
  height: 100%;
  @include max-md {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
  }
  @include max-sm {
    // margin-top: 60px;
  }
}
.JobPostingSection-left {
  border-right: 2px solid rgba(201, 213, 255, 0.3);
  width: 25%;
  display: flex;
  flex-direction: column;
  @include max-md {
    width: 100%;
    border: none;
    display: flex;
    flex-direction: row;
    column-gap: 32px;
  }
}
.JobPostingSection-right {
  width: 100%;
  // overflow-y: auto;

  // scrollbar-width: none;
  // -ms-overflow-style: none;

  // &::-webkit-scrollbar {
  //   display: none;
  // }
  @include max-md {
    width: 100%;
  }
}

.JobPostingSection-right-section {
  display: flex;
  row-gap: 24px;
  flex-direction: column;
  border-radius: 24px;
  border: 2px solid #e7f1f8;
  background: #fff;
  padding: 40px 48px;
  margin-top: 32px;
  @include max-md {
    padding: 20px 16px;
  }
  @include max-sm {
    padding: 20px 16px;
  }
}

.title {
  color: #101828;
  // // 
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  @include max-md {
    font-size: 24px;
    line-height: 32px;
  }
}

.left-item {
  display: flex;
  margin-bottom: 32px;
  column-gap: 12px;
  cursor: pointer;
  @include max-md {
    column-gap: 4px;
    margin-bottom: 8px;
  }
}

.selected {
  color: #101828;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}
.side-item {
  color: #75808f;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}
.section-title {
  color: #101828;
  display: flex;
  // // 
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  @include max-md {
    font-size: 20px;
    line-height: 32px;
  }
}

.right-section-earlier {
  margin-top: 48px;
}

.optionIcon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.select-field {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  color: #0f1322;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  column-gap: 8px;
  @include max-md {
    font-size: 12px;
    justify-content: space-between;
  }
}
.select-field-parking {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  color: #0f1322;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  column-gap: 8px;
  @include max-md {
    font-size: 12px;
    justify-content: space-evenly;
  }
}

.parking {
  display: flex;
  flex-direction: row;
}
.section-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  @include max-lg {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 12px;
  }
  @include max-md {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
  }
}

.preferenceDiv {
  display: flex;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  width: 200px;
  height: 47px;
  // width: 45%;
  padding: 0px 24px;
  align-items: center;
  column-gap: 12px;
  @include max-md {
    width: 100%;
  }
}

.inputField {
  height: 56px;
  display: flex;
  align-items: center;
  width: 100%;
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  &::placeholder {
    color: #101828;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}

.inputField-textArea {
  // height: 40px;
  // display: flex;
  // align-items: center;
  // width: 100%;
  color: #101828;
  padding: 0 12px;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  resize: vertical; /* Allow vertical resizing */
  height: 250px;
  width: 100%;
  &::placeholder {
    color: #101828;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  @include max-md {
    font-size: 11px;
  }
}

.inputField-wrap > input {
  @include max-slg {
    // margin-top: 20px;
  }
  @include max-md {
    // margin-top: 38px;
    padding-left: 0;
  }
}
.inputField-wrap > div {
  width: 100%;
}

.textArea {
  display: flex;
  // height: 332px;
  padding: 18px 24px;
  // align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1.5px solid rgba(201, 213, 255, 0.4);
  background: #fff;
  // margin-top: 24px;
  // margin-bottom: 32px;
  width: 100%;
  // min-height: 140px;
  &::placeholder {
    color: #101828;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  @include max-md {
    // margin-top: 12px;
    // margin-bottom: 12px;
  }
}

.inputField-textArea::placeholder {
  font-size: 12px !important;
}
.textArea:focus {
  border: 1.5px solid rgba(201, 213, 255, 0.4);
}

.selectInput > div > div > div {
  @include max-md {
    color: #101828;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 0 !important;
  }
}
.selectInput > div > div > div > svg {
  @include max-md {
    width: 16px;
    height: 16px;
  }
}

.selectInput {
  padding: 12px 16px 12px 20px;
  height: 56px !important;
  display: flex;
  width: 95%;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  align-items: center;
  @include max-md {
    width: 100%;
    height: 48px !important;
  }
}

.selectInput > div {
  background-color: #fff !important;
}

.profile-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 24px;
  column-gap: 24px;

  @include max-slg {
    display: flex;
    flex-direction: column;
    column-gap: 0px;
  }

  @include max-md {
    display: flex;
    flex-direction: column;
    column-gap: 0px;
  }
}

.autoComplete {
  column-gap: 10px;
  border-radius: 80px;
  border: none;
  background: #fff;
  box-shadow: none;
  display: flex;
  align-items: center;
  height: 40px;
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 100%;

  @include max-slg {
    // margin-top: 20px;
  }
  @include max-md {
    // margin-top: 38px;
  }
}

.select-container {
  padding-left: 12px;
  display: flex;
  width: 100%;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  align-items: center;
  height: 40px;
  @include max-xl {
    height: 40px;
  }
  @include max-lg {
    height: 40px;
  }
}

.select {
  border: 0;
  outline: none !important;
  width: 100%;
  color: #0f1322;
  // color: #858d98;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.select:focus {
  border: 0;
  outline: none !important;
  --tw-ring-shadow: none !important;
}

.selectOptions {
  border-radius: 24px;
}

.select-container select option {
  background-color: white; /* Background color for options */
  color: #333; /* Text color for options */
  border-radius: 16px;
}

.right-section-each {
  margin: 24px 0;
}

.labelText1 {
  color: #858d98;
  font-family: Open Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-sm {
    font-size: 12px;
  }
}
.labelText {
  color: #858d98;

  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-sm {
    font-size: 12px;
  }
}
.parkingDiv {
  display: flex;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  width: 100%;
  padding: 10px 24px;
  align-items: center;
  column-gap: 12px;
  justify-content: space-between;
  @include max-sm {
    flex-direction: column;
    width: 100%;
    row-gap: 12px;
  }
}
.parkingDiv-left {
  display: flex;
  column-gap: 16px;
  align-items: center;
  @include max-sm {
    flex-direction: row;
    align-items: center;
  }
}

.optionsSection {
  display: flex;
  column-gap: 26px;
  @include max-sm {
    column-gap: 4px;
    justify-content: space-evenly;
    width: 100%;
  }
}

.JobPostingSection-mobile-button {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.warning-div{
  display: flex;
  // gray color
  color: #858d98;
  font-size: 12px;
  font-style: normal;
}