@import "../../../styles/breakpoints";

.CompanySchedule-main {
  height: 100%;
  padding:10px;
}

.CompanySchedule {
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  height: calc(100vh - 100px);
  min-height: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  padding: 6px;
  margin-top: 20px;
  @include max-md {
    // padding: 32px;
    // box-shadow: none;
    height: calc(100vh - 250px);
    // height: calc(100vh - 150px);
  }
  @include max-sm {
    padding: 0px;
    height: calc(100vh - 250px);
  }
}

.calendar-body {
  display: flex;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  /* Hide the scrollbar for Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }
}

.CompanySchedule-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include max-md {
    flex-direction: column;
    align-items: unset;
  }
}

.CompanySchedule-selection {
  display: flex;
  align-items: center;
  column-gap: 24px;

  @include max-md {
  }
  @include max-sm {
  }
}

.CompanySchedule-header-right {
  display: flex;
  column-gap: 14px;
  align-items: flex-end;

  @include max-md {
    display: flex;
    flex-direction: row;
    row-gap: 14px;
    margin-top: 10px;
    justify-content: space-between;
  }
  @include max-sm {
  }
}

.backbutton {
  background-color: #101828;
  padding: 0px;
  border-radius: 20px;
  margin-right: 5px;
  cursor: pointer;
}

.backbutton-svg{
  rotate: 180deg;
}

.CompanySchedule-selection {
  // margin-top: 28px;
  // margin-bottom: 38px;

  @include max-md {
  }
  @include max-sm {
  }
}

.CompanySchedule-selection-buttons {
  display: flex;
  column-gap: 5px;
  width: 150px;

  @include max-md {
    width: 125px;
  }
}


.activeBtn {
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #101828;
  display: inline-flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Open Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  column-gap: 8px;
  @include max-md {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}



.title {
  color: #101828;
   
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  @include max-md {
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.calendarPicker {
  display: flex;
  align-items: center;
}
.calendarPicker > div {
  width: 100px !important;
}

.datePicker {
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border: none !important;
}
.datePicker > div > div {
  width: 250px !important;
}

.selectInputAvailability {
  padding: 5px;
  // height: 32px !important;
  display: flex;
  width: 250px;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #f0f3ff;
  align-items: center;
  @include max-md {
    width: 125px;
    height: 40px !important;
  }
}

.selectInputAvailability > div {
  background: #f0f3ff;
  // height: 30px !important;
  // height: auto !important;
  min-height: auto !important;
  @include max-md {
  }
}

.selectInputAvailability > div > div {
  height: auto;
  @include max-md {
    height: auto;
  }
}

.selectInputAvailability > div > div > div {
  // padding: 4px !important;

  // @include max-md {
  color: #101828;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 0 !important;
  // }
  @include max-md {
    font-size: 10px;
    line-height: 14px;
  }
}
.selectInputAvailability > div > div > div > svg {
  @include max-md {
    width: 16px;
    height: 16px;
  }
}

.CompanySchedule-header-left {
  display: flex;
  align-items: center;
  column-gap: 44px;
  @include max-slg {
    column-gap: 12px;
  }
  @include max-md {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 14px;
  }
}

.CompanySchedule-categories {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  width: 100vw;
  // overflow-x: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  /* Hide the scrollbar for Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }
}

.item-align{
  align-self: flex-end;
}

.primaryBtn {
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #fff;
  display: inline-flex;
  padding: 2px 11px;
  justify-content: center;
  align-items: center;
  color: #9fb5ff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.primaryBtn {
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #101828;
  display: inline-flex;
  padding: 0px 14px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
  flex-shrink: 0;
  height: 32px;
}
.secondaryBtn {
  display: inline-flex;
  padding: 14px 28px;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #fff;
  color: #9fb5ff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  flex-shrink: 0;
  height: 32px;
}

.indicatorText {
  color: #0f1322;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  @include max-md {
    font-size: 8px;
    line-height: 18px;
  }
}

.calendar-indicator-section {
  display: flex;
  column-gap: 25px;
  padding: 48px 0;
  justify-content: flex-end;
  @include max-md {
    padding: 8px 16px;
    column-gap: 5px;
  }
}

.calendar-indicator {
  display: flex;
  column-gap: 18px;
  align-items: center;
  @include max-md {
    column-gap: 6px;
  }
}

.activeBtn-main {
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #101828;
  display: inline-flex;
  padding: 6px 14px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  column-gap: 8px;
  @include max-md {
    // height: 32px;
    padding: 6px 16px;
    // width: 125px;
    font-size: 12px;

    line-height: 20px;
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.datePicker {
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  
  border: 1px solid #c0c4d6 inputClass;
  border-radius: 25px inputClass;
  height: 35px inputClass;
  margin: 1px 0 inputClass;
  padding: 2px 11px inputClass;
}
.datePicker > div > div {
  width: 250px !important;
}

.control-select{
  min-width: 300px;
  max-width: calc(100vw - 400px);
}