.line {
  display: inline-block;
  width: 13px;
  height: 15px;
  margin: 0 2px;
  border-radius: 15px;
}

@keyframes loadingA {
  0% {
    height: 15px;
  }
  50% {
    height: 35px;
  }
  100% {
    height: 15px;
  }
}

.load-1 {
  width: 100px;
  height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* =Animate the stuff
  ------------------------ */
.load-1 .line:nth-last-child(1) {
  animation: loadingA 1.5s 1s infinite;
}
.load-1 .line:nth-last-child(2) {
  animation: loadingA 1.5s 0.5s infinite;
}
.load-1 .line:nth-last-child(3) {
  animation: loadingA 1.5s 0s infinite;
}

/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 600px) {
  .load-1 {
    width: 80px;
    height: 50px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .load-1 {
    width: 100px;
    height: 60px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .load-1 {
    width: 100px;
    height: 60px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .load-1 {
    width: 100px;
    height: 60px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .load-1 {
    width: 100px;
    height: 60px;
  }
}
