@import "../../../styles/breakpoints";

.month-view {
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100% - 450px);
}
.month-view-main {
  display: flex;
  flex-direction: row;
  position: relative;
}
.month-view-full {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.weekContainer {
  display: flex;

  align-items: center;
  @include max-lg {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.weekLabel {
  display: flex;
  width: 10%;
  height: 82px;
  align-items: center;

  @include max-lg {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.datesContainer-head {
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #c9d5ff;
  border-bottom: 2px solid #496ff8;
}
.datesContainer {
  display: grid;
  grid-template-columns: repeat(9,calc(100% / 9));
  width: 100%;
  min-height: 82px;
  align-items: center;
  justify-items: center;
  @include max-lg {
    grid-template-columns: repeat(8, calc(100% / 8));
  }
// td of table must take same width

}

.available-color {
  color: #009633;
}

.datesContainer-1 {
  display: grid;
  grid-template-columns: repeat(9,calc(100% / 9));
  width: 100%;
  min-height: 40px;
  align-items: center;
  justify-items: center;
  @include max-lg {
    grid-template-columns:  repeat(8, calc(100% / 8));
  }
}

.dayLabel {
  color: #000;
  text-align: center;
  //font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.addAvailability {
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  display: flex;
  background-color: #009633;
  flex-direction: column;
  @include max-lg {
    height: 20px;
    width: 20px;
    border-radius: 5px;
  }
}

.dayLabel-day {
  color: #000;
  text-align: center;
  //font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-right: 2px solid #fff;
}

.dayLabel-day-none {
  @include max-lg {
    display: none;
  }
}

.dateLabel {
  color: #858d98;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @include max-lg {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.dateContainer {
  padding: 6px 4px;
  cursor: pointer;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 1px solid #c9d5ff;
}
.dateContainer-week {
  padding: 6px 4px;
  cursor: pointer;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-right: 2px solid #496ff8;
  border-bottom: 2px solid #fff;
  background-color: #c9d5ff;
  @include max-lg {
    display: none;
  }
}

.eventsContainer {
  color: #858d98;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include max-lg {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.eventsContainer-body {
  min-height: 45px;
  // height: 45px;
  padding: 1.75px 12px;
  border-radius: 6px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 220px;
  .eventsContainer-body-available {
    background-color: #e4faf2;
    border-left: 8px solid #16911b;
  }

  .eventsContainer-body-not-available {
    background-color: #f3d4d4;
    border-left: 8px solid #da011c;
  }

  &-partial {
    background-color: #b5b8c3;
    border-left: 8px solid #181203;
  }

  &-already_hired {
    background-color: #c9d5ff;
    border-left: 8px solid #181203;
  }
}
.eventsContainer-body-popover-secondary {
  min-height: 45px;
  // height: 45px;
  padding: 1.75px 12px;
  border-radius: 6px;
  text-align: left;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  max-width: 220px;
  &-available {
    background-color: #e4faf2;
    border-left: 8px solid #16911b;
  }

  &-not-available {
    background-color: #f3d4d4;
    border-left: 8px solid #da011c;
  }

  &-partial {
    background-color: #b5b8c3;
    border-left: 8px solid #181203;
  }

  &-already_hired {
    background-color: #c9d5ff;
    border-left: 8px solid #181203;
  }
}

.eventsContainer-body-popover {
  min-height: 45px;
  // height: 45px;
  padding: 1.75px 12px;
  border-radius: 6px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 220px;
  &-available {
    background-color: #e4faf2;
  }

  &-not available {
    background-color: #f3d4d4;
  }

  &-partial {
    background-color: #b5b8c3;
  }

  &-already_hired {
    background-color: #c9d5ff;
  }
}

.eventsContainer-body-head {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
  margin-top: 10px;
  column-gap: 12px;
}

.eventTitle {
  //font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 6px;

  max-width: 85px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &-available {
    color: #16911b;
    // background-color: #e4faf2;
  }

  &-not available {
    color: #da011c;
    // background-color: #f3d4d4;
  }

  &-partial {
    color: #181203;
    // background-color: #b5b8c3;
  }

  &-already_hired {
    color: #181203;
    // background-color: #c9d5ff;
  }
}

.eventTitle-popover {
  //font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 6px;
  // line-height: 16px;

  &-available {
    color: #16911b;
    // background-color: #e4faf2;
  }

  &-not available {
    color: #da011c;
    // background-color: #f3d4d4;
  }

  &-partial {
    color: #181203;
    // background-color: #b5b8c3;
  }

  &-already_hired {
    color: #181203;
    // background-color: #c9d5ff;
  }
}
.companyDetail {
  //font-family: Montserrat;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  // line-height: 16px;

  &-available {
    color: #16911b;
    // background-color: #e4faf2;
  }

  &-not available {
    color: #da011c;
    // background-color: #f3d4d4;
  }

  &-partial {
    color: #181203;
    // background-color: #b5b8c3;
  }

  &-already_hired {
    color: #181203;
    // background-color: #c9d5ff;
  }
}

.eventTime {
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-size: 7px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  &-available {
    color: #16911b;
    // background-color: #e4faf2;
  }

  &-not available {
    color: #da011c;
    // background-color: #f3d4d4;
  }

  &-partial {
    color: #181203;
    // background-color: #b5b8c3;
  }

  &-already_hired {
    color: #181203;
    // background-color: #c9d5ff;
  }
}

.redBackground {
  background-color: red;
}

.greenBackground {
  background-color: green;
}

.grayBackground {
  background-color: lightgray;
}

.companyData-top {
  display: flex;
  flex-wrap: wrap;
  // white-space: nowrap;
  column-gap: 12px;
  align-items: center;
}

.chevronDownIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.eventDiv-right {
  cursor: pointer;
  margin-left: 12px;
}

.datesContainer-bottom {
  display: flex;
  justify-content: space-between;
  padding: 6px 16px;
  @include max-lg {
    flex-direction: column;
  }
}

.labels {
  display: flex;
  column-gap: 36px;
  @include max-lg {
    flex-direction: column;
  }
}
.indicators {
  display: flex;
  column-gap: 20px;
  @include max-lg {
    flex-direction: column;
  }
}
.each-indicator {
  display: flex;
  align-items: center;
  column-gap: 18px;
}
.labelText {
  color: #0f1322;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.availability-available {
  background-color: #16911b;
  color: #e4faf2;
  //font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 5px;
  border-radius: 5px;
  margin: 2px;
}

.availability-unavailable {
  background-color: #da011c;
  color: #e4faf2;
  //font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 5px;
  border-radius: 5px;
  margin: 2px;
}

.availability-partial {
  background-color: #181203;
  color: #e4faf2;
  //font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 5px;
  border-radius: 5px;
  margin: 2px;
}
.schedule {
  border: #181203 solid 1px;
  color: #181203;
  //font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 5px;
  border-radius: 5px;
  margin: 2px;
  @include max-lg {
    display: none;
  }
}

.task-assigned {
  // bold and thick border
  border: #181203 solid 2px;
  color: #181203;
  //font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 5px;
  border-radius: 5px;
  margin: 2px;
}
