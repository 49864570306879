.header {
  // "h-12 bg-black text-white lg:bg-white lg:text-black px-5 lg:px-16 flex justify-between items-center"
  display: flex;
  justify-content: space-between;
  align-items: center;
  // height: 3rem;
  height: 114px;
  background: white;
  color: #000;
  padding-left: 4rem;
  padding-right: 4rem;
}

.inputWrap {
  border-radius: 16px;
  background: var(--greys-blue-grey-300, #f9fafb);
  display: flex;
  // width: 558.274px;
  height: 57px;
  padding: 2px 12px 2px 23px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
}
// .inputWrap > input {
//   border: 0;
//   background-color: transparent;
// }
.inputWrap > input:focus {
  outline: none;
  border: none;
}

.searchInput {
  padding-left: 12px;
  display: flex;
  width: 100%;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  align-items: center;
  height: 40px;
  padding-right: 12px;
}

/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 600px) {
  .header {
    background: black;
    color: white;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .header {
    background: black;
    color: white;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .header {
    background: black;
    color: white;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .header {
    background: white;
    color: #000;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .header {
    background: white;
    color: #000;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
