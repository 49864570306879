// @import "../../../styles/breakpoints";

.select-container {
  padding-left: 12px;
  display: flex;
  width: 100%;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  align-items: center;
  height: 40px;
}
.select-container-account {
  padding-left: 12px;
  display: flex;
  width: 100%;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  align-items: center;
  height: 40px;
}
.select-container-less-width {
  padding-left: 12px;
  display: flex;
  width: 100%;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  align-items: center;
  height: 40px;
}
.select-container-less-width-account {
  padding-left: 12px;
  display: flex;
  width: 100%;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  align-items: center;
  height: 40px;
}

.select-less-width {
  border: 0;
  outline: none !important;
  width: 100%;
  color: #0f1322;
  // color: #858d98;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border-radius: 40px;
}

.select {
  border: 0;
  outline: none !important;
  width: 92%;
  color: #0f1322;
  // color: #858d98;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.select:focus {
  border: 0;
  outline: none !important;
  --tw-ring-shadow: none !important;
}
.select-less-width:focus {
  border: 0;
  outline: none !important;
  --tw-ring-shadow: none !important;
}

.selectOptions {
  border-radius: 24px;
}

.select-container select option {
  background-color: white; /* Background color for options */
  color: #333; /* Text color for options */
  border-radius: 16px;
}

.error-title {
  color: #ff0000;
}

.error-input {
  color: #ff0000;
  border: 1.5px solid #ff0000;
  // also apply color to the icon which is in the same div as the icons are svg
  svg {
    color: #ff0000;
  }
}
