@import "../../../styles/breakpoints";

.inputField {
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 80px;
  font-size: 14px;
  padding-left: 0px;
  padding-right: 10px;
  @include max-xl {
    height: 40px;
  }
  @include max-lg {
    height: 40px;
  }
}

.inputField-wrap {
  column-gap: 10px;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  align-items: center;
  padding-left: 12px;
}