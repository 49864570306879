@import "../../../styles/breakpoints";

.ConfirmationModal {
  display: flex;
  flex-direction: column;
  background: #fff;
  // height: 628px;
  width: auto;
  height: auto;
  width: 400px;
  align-items: center;
  @include max-sm {
    width: 100%;
    padding: 24px;
  }
}

.title {
  color: #0f1322;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-top: 24px;
}

.sub-title {
  color: #0f1322;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-top: 24px;
}

.ConfirmationModal-icon {
  display: flex;
  width: 80px;
  height: 80px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 160px;
  background: rgba(201, 213, 255, 0.3);
}

.ConfirmationModal-buttons {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}
