@use "../../../styles/variables" as v;

.btn {
  display: flex !important;
  justify-content: center;
  align-items: center;
  // width: 100%;
  padding: 12px;
  font-weight: 600;
  border: 1px solid #00b0f0;
  margin-top: 25px;
  border-radius: 22px;
  color: white;
  // display: block;
  background-color: #00b0f0;
  font-family: "Montserrat-SemiBold";
  cursor: pointer;

  .closeBtn {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px;
    font-weight: 700;
    border: 1px solid white;
    margin-top: 25px;
    border-radius: 22px;
    color: white;

    background-color: black;
    cursor: pointer;
  }
  &-primary {
    @extend .btn;
    // color: v.$color-primary;
    background-color: v.$color-primary;
  }
  &-secondary {
    @extend .btn;
    color: v.$color-secondary;
  }
  &-dark {
    @extend .btn;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px;
    font-weight: 600;
    border: 1px solid v.$color-black;
    margin-top: 25px;
    border-radius: 22px;
    font-family: "Montserrat-SemiBold";
    cursor: pointer;
    background-color: v.$color-black !important;
    color: v.$color-white;
  }
}

.primaryBtn {
  color: black !important;
  background-color: #9fb5ff !important;
  width: 300px;
}

// .button {
//     width: 100%;
//     padding: 15px;
//     font-weight: 600;
//     border: 1px solid #00b0f0;
//     margin-top: 25px;
//     border-radius: 22px;
//     display: block;
//     color: white;
//     background-color: #00b0f0;
//     font-family: "Montserrat-SemiBold";
//     cursor: pointer;
//   }
.social-login-button {
  width: 100%;
  padding: 12px;
  font-weight: 600;
  border: 1px solid #000;
  margin-top: 25px;
  border-radius: 10px;
  display: block;
  color: #000;
  background-color: white;
  font-family: "Montserrat-SemiBold";
  cursor: pointer;
}

.space-button {
  border: none;
  margin-left: 15px;
  color: #fff;
  border-radius: 31px;
  font-weight: 500;
}
.badge-button {
  padding: 5px 35px !important;
  color: #fff;
  border-radius: 31px;
  // width: auto;
  border: none;
}

.user-button {
  border: none;
  margin: 15px 5px 8px 5px !important;
  color: #fff;
  border-radius: 12px;
  width: 100%;
  font-weight: 500;
  padding: 14px 16px 14px 18px;
}

.social-login-button-block {
  width: 100%;
  margin: auto;
}
@media (max-width: 1024px) {
  .user-button {
    margin: 0px 5px 8px 5px !important;
  }
}
