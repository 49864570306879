.modal-width {
  max-width: 580px;
}
.modal-card {
  max-width: 580px;
}



.modal-custom-body {
  padding: 60px 60px !important;
}
.modal-body {
  // width: 100vw;
  // background-color: aquamarine;
  min-width: max-content;
}

@media only screen and (max-width: 400px) {
  .modal-card {
    width:80%;
  }
}
@media (max-width: 767px) {
  .modal-custom-body {
    padding: 10px 10px !important;
  }
}

/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 600px) {
  .modal-body {
    min-width: auto;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .modal-body {
    min-width: auto;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .modal-body {
    min-width: auto;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .modal-body {
    min-width: auto;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
