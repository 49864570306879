@import "../../../styles/breakpoints";

.CompanyCard {
  // margin: 64px 32px;
  height: auto;

  flex-shrink: 0;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  // justify-content: space-between;
  padding-right: 40px;
  width: 280px;
  display: flex;
  flex-direction: column;
  @include max-sm {
    padding: 24px;
    width: 100%;
  }
}
.image {
  width: 48px;
 height: 40px;
 border-radius: 50%;
  flex-shrink: 0;
  @include max-sm {
    width: 43.787px;
    height: 43.787px;
  }
}

.urgentHiring {
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #dc2d2d;
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  width: max-content;
  margin-bottom: 32px;
}

.company {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.company-details {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.description {
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  @include max-sm {
    font-size: 12.771px;
    line-height: 18.245px;
  }
}

.name {
  color: #101828;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  @include max-sm {
    font-size: 14.596px;
    line-height: 18.245px;
  }
}

.details-div {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 32px;
  row-gap: 32px;
  @include max-sm {
    row-gap: 28px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @include max-sm {
    row-gap: 14px;
  }
}

.lastItem {
  margin-top: 32px;
  @include max-sm {
    margin-top: 28px;
  }
}

.description-value {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  @include max-sm {
    font-size: 12.771px;
    line-height: 18.245px;
  }
}

.company-about {
  margin-top: 48px;
}

.title {
  color: #101828;
  // 
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 16px;
  @include max-sm {
    font-size: 18px;
  }
}

.ratingCard {
  width: 144px !important;
  height: 120px !important;
  @include max-md {
    width: 120px !important;
    height: 84px !important;
    border-top-left-radius: 10.013px !important; 
    border-top-right-radius: 10.013px !important;
  }
}
.googleText {
  font-size: 16.089px !important;
  line-height: 21.453px !important;
  @include max-md {
    font-size: 11.323px !important;
    line-height: 15.097px !important;
  }
}
.ratingText {
  font-size: 28.603px !important;
  @include max-md {
    font-size: 20.129px !important;
  }
}
.logoClass {
  width: 31.425px !important;
  height: 31.425px !important;
  @include max-md {
    width: 22.114px !important;
    height: 22.114px !important;
  }
}

.starClass {
  font-size: 1em !important;
}

.subTitleClass {
  font-size: 8.045px !important;
  line-height: 10.726px !important;
}

.company-about-rating {
  margin-top: 40px;
}
