.star {
  font-size: 1.5rem;
  color: #ccc;
  // margin-right: 4px;
  // @include max-md {
  //   font-size: 9.46px;
  // }
}

.star.filled {
  color: gold;
}
