@import "../../../styles/breakpoints";

.AvailabilitySchedule {
  display: flex;
  flex-direction: column;
  background: #fff;
  // height: 550px;
  width: 620px;
  // padding: 40px;
  align-items: center;

  @include max-md {
    padding: 24px 10px;
    width: auto;
    height: auto;
    min-width: auto;
  }
  @include max-sm {
    // margin-top: 60px;
  }
}
.AvailabilitySchedule-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.AvailabilitySchedule-main-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include max-md {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.timeRangeClassName{
  column-gap: 10px !important;
  border-radius: 5px !important;
  border: 1.5px solid rgba(15, 19, 34, 0.08) !important;
  background: #fff !important;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04) !important;
  display: flex !important;
  align-items: center !important;
  padding-left: 12px !important;
  width: 100% !important;
  height: 29px !important;
}

.AvailabilitySchedule-main {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 10px;
  margin-bottom: 10px;
  @include max-md {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 0;
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.partial-section-main {
  display: flex;
  flex-direction: column;
  row-gap: 13px;
  @include max-md {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}
.partial-section {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  row-gap: 16px;
  @include max-md {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.date {
  color: #858d98;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 8px;
    line-height: 14px;
  }
}

.AvailabilitySchedule-selectField {
  display: flex;
  margin-top: 23px;
  border-bottom: 1px solid #c9d5ff;
  width: 67%;
  margin: auto;
  justify-content: center;
  @include max-md {
    border-bottom: none;
  }
}

.AvailabilitySchedule-dates {
  width: 95%;
}

.AvailabilitySchedule-eachSection {
  // margin-top: 23px;
  // border-bottom: 1px solid #c9d5ff;
  padding-top: 2px;
  // padding-bottom: 38px;
  @include max-md {
    padding-top: 0;
  }
}

.eachSection-main {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include max-md {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.select-field {
  display: flex;
  column-gap: 16px;
  color: #000;
 //
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 37px;
  padding-bottom: 4px;

  @include max-md {
    font-size: 10px;
    column-gap: 2px;
  }
}

.title {
  color: #101828;
 //
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;

  @include max-md {
    font-size: 20px;
    line-height: 28px;
  }
}

.subTitle {
  color: #75808f;
  text-align: center;
  font-family: Open Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 6px;
  @include max-md {
    font-size: 10px;
    line-height: 14px;
    margin-top: 6px;
  }
}

.section-title {
  color: #101828;
 //
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.AvailabilitySchedule-icon {
  display: flex;
  width: 80px;
  height: 80px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 160px;
  background: rgba(201, 213, 255, 0.3);
}

.inputField {
  height: 28px;
  display: flex;
  align-items: center;
  width: 100%;
  color: #000000;
  font-family: Open Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 0px 10px;
  
  // placeholder font size
  &::placeholder {
    font-size: 10px;
  }
  @include max-md {
    height: 28px;
    // height: 38px;
  }
}

.inputField-wrap {
  column-gap: 10px;
  border-radius: 10px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  align-items: center;
  width: 100%;
  @include max-md {
    width: 100%;
  }
}

.inputField-wrap-time {
  column-gap: 10px;
  border-radius: 80px !important;
  border: 1.5px solid rgba(15, 19, 34, 0.08) !important;
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  align-items: center;
  padding-left: 12px;
  width: 90px;
  height: 56px;
  @include max-xl {
    height: 40px;
  }
  @include max-lg {
    height: 40px;
  }
}

.textArea {
  // height: 332px;
  padding: 24px 28px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1.5px solid rgba(201, 213, 255, 0.4);
  background: #fff;
  margin-top: 24px;
  margin-bottom: 32px;
  width: 100%;
  height: 330px;
  @include max-md {
    height: 176px;
  }
}
.textArea:focus {
  border: 1.5px solid rgba(201, 213, 255, 0.4);
}

.buttonDiv {
  display: flex;
  column-gap: 16px;
}

.AvailabilitySchedule-buttons {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.heading {
  color: #000;
 //
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  @include max-md {
    font-size: 10px;
    line-height: 30px;
    margin-left: 6px;
    font-weight: 400;
  }
}

.datePicker-field {
  border-radius: 10px;
  border: 1.5px solid rgba(201, 213, 255, 0.4);
  background: #fff;
  width: 436px;
  height: 35px;
  @include max-md {
    width: 100%;
  }
}

.datePicker-field > div {
  @include max-md {
    width: 92%;
  }
}

.datePicker-field > div > div > div > input {
  margin-left: 12px;
  border: none !important;
  width: 408px;
  height: 39px;
  padding: 7px 8px;
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    width: 100%;
  }
}

.avaiability-div{
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  margin-top: 10px;

  @include max-md {
    flex-direction: column;
    row-gap: 8px;
    width:100%;
  }
}
.eachSection-dates-availability {
  display: grid;
  // grid template have 2 item for large screen and 1 for mobile
  grid-template-columns: 1fr 1fr;
}

.eachSection-dates {
  row-gap: 5px;
  display: grid;
  // grid template have 2 item for large screen and 1 for mobile
  // grid-template-columns: 1fr 1fr;
  margin-top: 5px;
  @include max-md {
    row-gap: 5px;
    width: 100%;
  }
}

.selectInputAvailability {
  padding: 0px 0px 0px 0px;
  height: 30px !important;
  display: flex;
  width: 150px !important;
  border-radius: 10px !important;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  @include max-md {
    width: 100% !important;
    height: 30px !important;
    padding: 0px 0px 0px 0px !important;
  }
}

.selct-input{
  height: 20px !important;
  font-size: 10px !important;
  padding: 0px 8px;
}

.select-div {
  display: flex;
  align-items: center;
  column-gap: 10px;
  @include max-md {
    justify-content: space-between;
    width: 100%;
  }
}
.label {
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 10px;
  }
}

.border {
  border-bottom: 1px solid #c9d5ff;
  width: 70%;
  margin: auto;
  margin-top: 10px;
  @include max-md {
    margin-top: 10px;
  }
}

.input-checkbox {
  width: 23px !important;
  height: 23px !important;
  @include max-md {
    width: 12px;
    height: 12px;
  }
  .input-checkbox > input {
  }
}
.input-checkbox:checked {
  width: 23px !important;
  height: 23px !important;
  @include max-md {
    width: 12px;
    height: 12px;
  }
}

.saveButton {
  width: 98px;
  height: 40px;
  border-radius: 80px;
  background: #c9d5ff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #101828;
  text-align: center;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  @include max-md {
    width: 90px;
    height: 25px;
    text-transform: uppercase;
  }
}
.cancelButton {
  width: 98px;
  height: 40px;
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #9fb5ff;
  text-align: center;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  @include max-md {
    width: 90px;
    height: 25px;
    text-transform: uppercase;
  }
}

.add-fields {
  cursor: pointer;
}
.partial-action {
  display: flex;
  justify-content: space-evenly;
}
.partial-actionBtn {
  display: flex;
  border-radius: 80px;
  background: #c9d5ff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  align-items: center;
  justify-content: space-evenly;

  width: 148px;
  padding: 4px 8px;
  color: #101828;

  text-align: center;
  font-family: Open Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 2px;
}

.collsibleClassName {
}
.collsibleClass-trigier {
  font-size: 14px;
  width: 100%;
  display: flex;
  background: aquamarine;
  padding: 5px;

  color: #000;
 //
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  @include max-md {
    font-size: 10px;
    line-height: 26px;
    margin-left: 6px;
    font-weight: 400;
  }
}
