@import "../../styles/breakpoints";

.NotificationPage {
  flex-shrink: 0;

  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  // height: calc(100vh - 250px);
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  padding: 20px;
  @include max-md {
    padding: 32px;
    height: calc(100vh - 150px);
  }
}

.NotificationPage-main {
  display: flex;
  padding: 20px 0px 40px 0px;
  justify-content: space-between;
  height: 100%;
  @include max-md {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
  }
}
.NotificationPage-left {
  border-right: 2px solid rgba(201, 213, 255, 0.3);
  width: 25%;
  display: flex;
  flex-direction: column;
  @include max-md {
    width: 100%;
    border: none;
    display: flex;
    flex-direction: row;
    column-gap: 32px;
    
  }
}
.NotificationPage-right {
  width: 100%;
  // overflow-y: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
  @include max-md {
    width: 100%;
  }
}

.NotificationPage-right-section {
  display: flex;
  row-gap: 24px;
  flex-direction: column;
  border-radius: 24px;
  border: 2px solid #e7f1f8;
  background: #fff;
  padding: 40px 48px;
  margin-top: 32px;
  @include max-md {
    padding: 20px 16px;
  }
  @include max-sm {
    padding: 20px 16px;
  }
}

.title {
  color: #101828;
  // font-family: Fredoka;
  text-align: left;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  @include max-md {
    font-size: 24px;
    line-height: 32px;
  }
}

.left-item {
  display: flex;
  margin-bottom: 32px;
  column-gap: 12px;
  cursor: pointer;
  @include max-md {
    column-gap: 4px;
    margin-bottom: 8px;
  }
}

.selected {
  color: #101828;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}
.side-item {
  color: #75808f;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}
.section-title {
  color: #101828;
  // font-family: Fredoka;
  text-align: left;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  @include max-md {
    font-size: 20px;
    line-height: 32px;
  }
}

.right-section-recent {
  margin-bottom: 20px;
}
