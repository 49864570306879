@import "../../../styles/breakpoints";

.month-view {
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100% - 450px);
}
.month-view-main {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}
.month-view-full {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.weekContainer {
  display: flex;

  align-items: center;
  @include max-lg {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.weekLabel {
  display: flex;
  // width: 120px;
  width: 10%;
  height: 82px;
  align-items: center;

  @include max-lg {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.datesContainer-head {
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #c9d5ff;
  border-bottom: 2px solid #496ff8;
}
.datesContainer {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  width: 100%;
  min-height: 82px;
  align-items: center;
  justify-items: center;
  @include max-lg {
    grid-template-columns: repeat(7, 1fr);
    // hide first td of tr of table
    tr {
      td:first-child {
        display: none;
      }
    }
  }
}
.datesContainer-1 {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  width: 100%;
  min-height: 40px;
  align-items: center;
  justify-items: center;
  @include max-lg {
    grid-template-columns: repeat(7, 1fr);
    // hide first td of tr of table
    tr {
      td:first-child {
        display: none;
      }
    }
  }
}

.available-color {
  color: #009633;
}

.availability-cell {
  // flex flex-row-reverse justify-between items-center
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  @include max-md {
    flex-direction: column;
  }
}

.dayLabel {
  color: #000;
  text-align: center;
  //font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.dayLabel-day {
  color: #000;
  text-align: center;
  //font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-right: 2px solid #fff;

  @include max-lg {
    // hide all content except first
    display: none;

    &:first-child {
      display: flex;
      flex-direction: column;
    }
  }
}

.focus {
  background-color: #c9d5ff;
  // opacity: 0.5;
}

.dateLabel {
  color: #858d98;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @include max-lg {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.clander-cell {
  padding: 6px 4px;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #c9d5ff;
  position: relative;
}
.dateContainer {
  padding: 6px 4px;
  cursor: pointer;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 1px solid #c9d5ff;
  position: relative;
}

.task-stick {
  height: 25px;
  width: 5px;
  border-radius: 2px;
  margin: 3px;
}
// "completed" | "rejected" | "inProgress" | "declined" | "approved"

.task-stick-inProgress {
  background-color: #181203;
}

.task-stick-declined {
  background-color: rgb(228, 174, 38);
}

.task-stick-approved {
  background-color: #819bf7;
}
.task-stick-hired {
  background-color: #009633;
}

.task-stick-rejected {
  background-color: #da011c;
}

.task-stick-completed {
  background-color: #c9d5ff;
}

// "completed" | "rejected" | "inProgress" | "declined" | "approved"
.task-inProgress {
  border: #181203;
}
.task-approved {
  border: #819bf7;
}

.task-declined {
  border: rgb(228, 174, 38);
}

.task-hired {
  border: #009633;
}

.task-rejected {
  border: #da011c;
}

.task-completed {
  border: #c9d5ff;
}

.task-item {
  height: 35px;
  width: 80%;
  border-width: 3px;
  border-style: solid;
  border-radius: 5px;
  display: flex;
  margin: 5px;
  flex-direction: row;

  @include max-md {
    height: 20px;
    width: 20px;
    justify-content: center;
    align-items: center;
  }
}

.task-info {
  display: flex;
  overflow: hidden;

  flex-direction: column;
}

.task-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 8px;
  width: 50px;
  @include max-md{
    width: auto;
  }
}
.task-time {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 7px;
  width: 75px;
}

.dateContainer-week {
  padding: 6px 4px;
  cursor: pointer;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-right: 2px solid #496ff8;
  border-bottom: 2px solid #fff;
  background-color: #c9d5ff;
}

.eventsContainer {
  color: #858d98;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 60px;
}
.add-icon {
  cursor: pointer;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
}

.eventsContainer-body {
  min-height: 45px;
  // height: 45px;
  padding: 1.75px 12px;
  border-radius: 6px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 220px;
  &-available {
    background-color: #e4faf2;
    border-left: 8px solid #16911b;
  }

  &-unavailable {
    background-color: #f3d4d4;
    border-left: 8px solid #da011c;
  }

  &-partially_available {
    background-color: #b5b8c3;
    border-left: 8px solid #181203;
  }

  &-already_hired {
    background-color: #c9d5ff;
    border-left: 8px solid #181203;
  }
}
.eventsContainer-body-popover-secondary {
  min-height: 45px;
  // height: 45px;
  padding: 1.75px 12px;
  border-radius: 6px;
  text-align: left;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  max-width: 220px;
  &-available {
    background-color: #e4faf2;
    border-left: 8px solid #16911b;
  }

  &-unavailable {
    background-color: #f3d4d4;
    border-left: 8px solid #da011c;
  }

  &-partially_available {
    background-color: #b5b8c3;
    border-left: 8px solid #181203;
  }

  &-already_hired {
    background-color: #c9d5ff;
    border-left: 8px solid #181203;
  }
}

.eventsContainer-body-popover {
  min-height: 45px;
  // height: 45px;
  padding: 1.75px 12px;
  border-radius: 6px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 220px;
  &-available {
    background-color: #e4faf2;
  }

  &-unavailable {
    background-color: #f3d4d4;
  }

  &-partially_available {
    background-color: #b5b8c3;
  }

  &-already_hired {
    background-color: #c9d5ff;
  }
}

.eventsContainer-body-head {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
  margin-top: 10px;
  column-gap: 12px;
}

.eventTitle {
  //font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 6px;
  // line-height: 16px;
  max-width: 85px; /* Set a maximum width for the element */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Display ellipsis (...) for overflowed text */
  &-available {
    color: #16911b;
    // background-color: #e4faf2;
  }

  &-unavailable {
    color: #da011c;
    // background-color: #f3d4d4;
  }

  &-partially_available {
    color: #181203;
    // background-color: #b5b8c3;
  }

  &-already_hired {
    color: #181203;
    // background-color: #c9d5ff;
  }
}

.eventTitle-popover {
  //font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 6px;
  // line-height: 16px;

  &-available {
    color: #16911b;
    // background-color: #e4faf2;
  }

  &-unavailable {
    color: #da011c;
    // background-color: #f3d4d4;
  }

  &-partially_available {
    color: #181203;
    // background-color: #b5b8c3;
  }

  &-already_hired {
    color: #181203;
    // background-color: #c9d5ff;
  }
}
.companyDetail {
  //font-family: Montserrat;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  // line-height: 16px;

  &-available {
    color: #16911b;
    // background-color: #e4faf2;
  }

  &-unavailable {
    color: #da011c;
    // background-color: #f3d4d4;
  }

  &-partially_available {
    color: #181203;
    // background-color: #b5b8c3;
  }

  &-already_hired {
    color: #181203;
    // background-color: #c9d5ff;
  }
}

.eventTime {
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-size: 7px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  &-available {
    color: #16911b;
    // background-color: #e4faf2;
  }

  &-unavailable {
    color: #da011c;
    // background-color: #f3d4d4;
  }

  &-partially_available {
    color: #181203;
    // background-color: #b5b8c3;
  }

  &-already_hired {
    color: #181203;
    // background-color: #c9d5ff;
  }
}

.redBackground {
  background-color: red;
}

.greenBackground {
  background-color: green;
}

.grayBackground {
  background-color: lightgray;
}

.companyData-top {
  display: flex;
  flex-wrap: wrap;
  // white-space: nowrap;
  column-gap: 12px;
  align-items: center;
}

.chevronDownIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.eventDiv-right {
  cursor: pointer;
  margin-left: 12px;
}

.actionButton {
  cursor: pointer;
}

.datesContainer-bottom {
  display: flex;
  justify-content: space-between;
  padding: 6px 16px;
  width: 100%;
  @include max-lg {
    flex-direction: column;
  }
}

.labels {
  display: flex;
  column-gap: 36px;
  @include max-lg {
    flex-direction: column;
  }
}

.indicators {
  display: flex;
  column-gap: 20px;
  flex-direction: row;

  @include max-md {
    flex-direction: column;
  }
}
.each-indicator {
  display: flex;
  align-items: center;
  column-gap: 18px;
}
.labelText {
  color: #0f1322;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.availability-available {
  background-color: #16911b;
  color: #e4faf2;
  //font-family: Montserrat;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 4px;
  border-radius: 3px;
  margin: 0px;

  @include max-lg {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 5px;
    display: block;
    height: 5px;
    border-radius: 50px;
  }
}

.availability-unavailable {
  background-color: #da011c;
  color: #e4faf2;
  //font-family: Montserrat;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 4px;
  border-radius: 3px;
  margin: 0px;
  @include max-lg {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 5px;
    display: block;
    height: 5px;
    border-radius: 50px;
  }
}

.availability-partial {
  background-color: #181203;
  color: #e4faf2;
  //font-family: Montserrat;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 4px;
  border-radius: 3px;
  margin: 0px;
  @include max-lg {
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 5px;
    height: 5px;
    border-radius: 50px;
  }
}
.schedule {
  border: #181203 solid 1px;
  color: #181203;
  //font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 5px;
  border-radius: 5px;
  margin: 2px;
}
