@import "../../../styles/breakpoints";

.WorkerCardLarge {
  // margin: 64px 32px;
  // height: 234px;

  flex-shrink: 0;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  @include max-lg {
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: auto;
  }
}

.WorkerCardLarge-left {
  display: flex;
  column-gap: 24px;
  flex: 0.5;
}
.WorkerCardLarge-mid {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* row-gap: 24px; */
  flex: 0.2;
  justify-content: space-between;
  @include max-md {
    align-items: center;
    margin-top: 24px;
  }
}
.WorkerCardLarge-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* row-gap: 24px; */
  flex: 0.2;
  justify-content: center;
  @include max-md {
    align-items: center;
    margin-top: 24px;
  }
}
.button-div {
  display: flex;
  column-gap: 16px;
}

.left-details-iconText {
  display: flex;
  column-gap: 8px;
  margin-top: 10px;
  align-items: center;
  flex-wrap: wrap;
  @include max-md {
    margin-bottom: 20px;
  }
}

.left-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // max-width: 65%;
  @include max-md {
    row-gap: 14px;
    width: 100%;
  }
}

.left-details-top {
  display: flex;
  flex-direction: column;
}

.left-details-bottom {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @include max-md {
    row-gap: 12px;
  }
}
.left-image {
  position: relative;
  flex-shrink: 0;
}
.companyLogo {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 50%;

  @include max-md {
    width: 48px;
   height: 48px;
  }
}

.title {
  color: #101828;
  // 
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  @include max-md {
    font-size: 20px;
    line-height: 28px;
  }
}

.companyName {
  color: #101828;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  @include max-md {
    font-size: 10px;
    line-height: 18px;
  }
}

.description {
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 10px;
    line-height: 16px;
  }
}

.city {
  color: #1c53f4;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.country {
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.userDescription {
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 24px;

  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.rate {
  color: #1c53f4;
  text-align: right;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  @include max-md {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
  }
}

.available {
  border-radius: 8px;
  background: #e7ffe1;
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;

  @include max-md {
    font-size: 10px;
    line-height: 16px;
  }
}

.available-text {
  color: #26c700;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 12px;
    line-height: 16px;
  }
}
.topRated {
  border-radius: 8px;
  background: #101828;
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  column-gap: 4px;

  @include max-md {
    font-size: 10px;
    line-height: 16px;
  }
}
.topRated-text {
  color: #fff;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  @include max-md {
    font-size: 12px;
    line-height: 16px;
  }
}

.availableDiv {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  @include max-md {
    margin-bottom: 12px;
  }
}

.socialIcon-div {
  border-radius: 80px;
  background: rgba(201, 213, 255, 0.2);
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.WorkerCardLarge-right-below {
  display: flex;
  column-gap: 24px;
}

.available-dot {
  position: absolute;
  right: 0px;
  position: absolute;
  top: 10%;
  @include max-sm {
    top: 50%;
  }
}

.left-details-top-main {
  display: flex;
  align-items: center;
  column-gap: 19px;
  @include max-md {
    justify-content: space-between;
  }
}

.left-details-top-rating {
  border-radius: 32px;
  background: rgba(241, 245, 254, 0.5);
  display: inline-flex;
  height: 32px;
  padding: 8px 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
}

.rating-text {
  color: #1c53f4;
  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 145.455% */
  text-transform: capitalize;
}

.WorkerCardLarge-right-buttons {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
