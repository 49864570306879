.tableItem {
  padding: 0 8px;
  text-align: center;
  // " py-0 lg:py-4 px-2 text-center"
}
.tableItem-last {
  padding: 0 8px;
  text-align: end;
  // " py-0 lg:py-4 px-2 text-center"
}

.item-background{
  background-color: #fae5e5;
}

.tableItem-options {
  display: flex;
  justify-content: center;
}

.seeDetails {
  border-radius: 10px;
  background: #9fb5ff;
  width: 147px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .seeDetails_text {
    color: #000;

    // font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.notification-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
   cursor: pointer;
}
.notification-alert {
  position: absolute;
  top: 0;
  right: 25%;
 
}

.seeMore {
  display: flex;
  justify-content: center;
}

.verified {
  border-radius: 10px;
  background: #1ce354;
 
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  margin: auto;
}

.rejected {
  border-radius: 10px;
  background: #ff0000;

  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: auto;
  padding: 2px;
}
.unverified {
  border-radius: 10px;
  background: #ff0000;

  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: auto;
  padding: 2px;
}

.inProcess {
  border-radius: 10px;
  background: #ffd600;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  padding: 2px;
 
}

/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
