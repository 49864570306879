@import "../../../styles/breakpoints";

.uploadImg-div {
  position: relative;
  width: 100px;
  height: 100px;
  @include max-md {
    width: 56px;
    height: 56px;
  }
}

.uploadImg {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 60px;
  @include max-md {
    width: 56px;
    height: 56px;
  }
}

.editIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  background: #fff;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include max-md {
    width: 16px;
    height: 16px;
  }
}

.uploadCard {
  display: flex;
  display: f;
  flex-direction: column;
  align-items: center;
}
