@import "../../styles/breakpoints";

.InviteWorker {
  flex-shrink: 0;
  // background: #fff;
  // box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  // height: calc(100vh - 250px);
  height: calc(100%);
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  // padding: 48px;
  @include max-md {
    // padding: 32px;
  }
  @include max-sm {
    // padding: 16px;
  }
}

.InviteWorker-main {
  border-radius: 24px;
  border: 2px solid #e7f1f8;
  background: #fff;
  flex-shrink: 0;
  height: 100%;

  @include max-md {
    display: flex;
    flex-direction: column;
    // padding: 16px 0;
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.title {
  color: #101828;
  // // 
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 24px;
  @include max-md {
    font-size: 24px;
    line-height: 32px;
  }
}
.header-top {
  border-bottom: 2px solid rgba(201, 213, 255, 0.3);
  padding: 33px 40px 17px;
  @include max-md {
    padding: 16px 20px;
  }
}
.header-bottom {
  padding: 21px 35px 17px;
  border-bottom: 2px solid rgba(201, 213, 255, 0.3);
  display: flex;
  justify-content: space-between;
  @include max-md {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    row-gap: 16px;
    padding: 16px 20px;
  }
}

.activeButton {
  border-radius: 80px !important;
  background: #101828 !important;
  height: 40px !important;
  padding: 10px 17px !important;
  justify-content: center !important;
  align-items: center !important;
  flex-shrink: 0 !important;
  color: #fff !important;
  font-family: Open Sans !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-transform: capitalize !important;
  @include max-md {
    font-size: 12px !important;
    padding: 8px 16px !important;
  }
}

.inactiveButton {
  border: none !important;
  border-radius: 80px !important;
  background: #fff !important;
  height: 40px !important;
  padding: 10px 20px !important;
  justify-content: center !important;
  align-items: center !important;
  flex-shrink: 0 !important;
  color: #9fb5ff !important;
  font-family: Open Sans !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-transform: capitalize !important;
  margin-left: 0 !important;
  @include max-md {
    font-size: 12px !important;
    padding: 8px 16px !important;
  }
}

.toggleTabs {
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #fff;
  height: 45px;
  padding: 4px;
  align-items: center;
  display: flex;
  flex-shrink: 0;
}


.inputField {
 height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  @include max-md {
   height: 40px;
  }
}

.inputField-wrap {
  column-gap: 10px;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 16px;

  @include max-slg {
    // margin-top: 20px;
  }
  @include max-md {
    // margin-top: 38px;
  }
}

.InviteWorker-main-workers {
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  @include max-md {
    padding: 16px;
  }
}

.workerClass {
  height: auto !important;
  width: auto !important;
}

.midSectionClass {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0 !important;
}
