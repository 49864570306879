.input-group {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // padding: 0px 5px;
  margin: 0.3rem;
}

.input-group-text {
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  color: rgb(122, 121, 121);
}

/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 600px) {
  .input-group {
    margin-bottom: 8px;
  }
  .input-group-text {
    font-size: 12px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .input-group-text {
    font-size: 12px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
