@import "../../../styles/breakpoints";

.month-view {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 100%;
  justify-content: space-between;
}

.full-screen{
  position: absolute;
  top: 0;
  left:0;
  width: 100% !important;
  height: 100% !important;
  background-color: #fff;
  padding:10px;
}

// .primaryBtn {
//   border-radius: 80px;
//   border: 1.5px solid #c9d5ff;
//   background: #fff;
//   display: inline-flex;
//   padding: 2px 11px;
//   justify-content: center;
//   align-items: center;
//   color: #9fb5ff;
//   font-family: Open Sans;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 20px;
// }
.primaryBtn {
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #101828;
  display: inline-flex;
  padding: 0px 14px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
  flex-shrink: 0;
  height: 32px;
}
.secondaryBtn {
  display: inline-flex;
  padding: 14px 28px;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #fff;
  color: #9fb5ff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  flex-shrink: 0;
  height: 32px;
}

.weekContainer {
  display: flex;

  align-items: center;
  @include max-md {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}
.no-worker-width{
  width: 100% !important;
}
.weekLabel {
  display: flex;
  width: 10%;
  height: 82px;
  align-items: center;

  @include max-md {
    height: 40px;
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.datesContainer-head {
  display: flex;
  // display: grid;
  // grid-template-columns: auto auto auto auto auto auto auto auto;
  width: 100%;
  height: 82px;

  @include max-md {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

// .datesContainer {
//   // display: grid;
//   // grid-template-columns: repeat(9, 1fr);
//   display: flex;
//   overflow-x: auto;
//   column-gap: 78px;
//   width: 90%;
//   min-height: 82px;
//   // height: 82px;
//   border-bottom: 1px solid rgba(59, 204, 65, 0.6);
//   align-items: center;
//   justify-items: center;

//   @include max-md {
//   }
//   @include max-sm {
//     // margin-top: 60px;
//   }
// }

.workerHeading {
  color: #101828;
   
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  height: 54px;
  display: flex;
  align-items: center;

  @include max-md {
    font-size: 14.977px;
    line-height: 19.97px;
    height: 40px;
  }
  @include max-sm {
  }
}

.dayLabel {
  color: #75808f;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  @include max-md {
    font-size: 8.737px;
    line-height: 12.481px;
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.dayLabel-day {
  color: #75808f;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  // width: 200px;
  @include max-md {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.dateLabel {
  color: #858d98;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @include max-md {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.dateContainer {
  padding: 6px 4px;
}

.eventsContainer {
  color: #858d98;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 60px;
  // width: 170px;
  // width: 200px;
  // flex: 1;

  @include max-md {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.eventsContainer-body {
  min-height: 45px;
  // height: 45px;
  padding: 1.75px 12px;
  border-radius: 6px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 220px;
  &-available {
    background-color: #e4faf2;
    border-left: 8px solid #16911b;
  }

  &-unavailable {
    background-color: #f3d4d4;
    border-left: 8px solid #da011c;
  }

  &-partially_available {
    background-color: #b5b8c3;
    border-left: 8px solid #181203;
  }

  &-already_hired {
    background-color: #c9d5ff;
    border-left: 8px solid #181203;
  }
}
.eventsContainer-body-popover-secondary {
  min-height: 45px;
  // height: 45px;
  padding: 1.75px 12px;
  border-radius: 6px;
  text-align: left;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  max-width: 220px;
  &-available {
    background-color: #e4faf2;
    border-left: 8px solid #16911b;
  }

  &-unavailable {
    background-color: #f3d4d4;
    border-left: 8px solid #da011c;
  }

  &-partially_available {
    background-color: #b5b8c3;
    border-left: 8px solid #181203;
  }

  &-already_hired {
    background-color: #c9d5ff;
    border-left: 8px solid #181203;
  }
}

.eventsContainer-body-popover {
  min-height: 45px;
  // height: 45px;
  padding: 1.75px 12px;
  border-radius: 6px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 220px;
  &-available {
    background-color: #e4faf2;
  }

  &-unavailable {
    background-color: #f3d4d4;
  }

  &-partially_available {
    background-color: #b5b8c3;
  }

  &-already_hired {
    background-color: #c9d5ff;
  }
}

.eventsContainer-body-head {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
  margin-top: 10px;
  column-gap: 12px;
}

.eventTitle {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 6px;
  // line-height: 16px;
  max-width: 115px; /* Set a maximum width for the element */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Display ellipsis (...) for overflowed text */
  &-available {
    color: #16911b;
    // background-color: #e4faf2;
  }

  &-unavailable {
    color: #da011c;
    // background-color: #f3d4d4;
  }

  &-partially_available {
    color: #181203;
    // background-color: #b5b8c3;
  }

  &-already_hired {
    color: #181203;
    // background-color: #c9d5ff;
  }
}

.eventTitle-popover {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 6px;
  // line-height: 16px;

  &-available {
    color: #16911b;
    // background-color: #e4faf2;
  }

  &-unavailable {
    color: #da011c;
    // background-color: #f3d4d4;
  }

  &-partially_available {
    color: #181203;
    // background-color: #b5b8c3;
  }

  &-already_hired {
    color: #181203;
    // background-color: #c9d5ff;
  }
}
.companyDetail {
  font-family: Montserrat;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  // line-height: 16px;

  &-available {
    color: #16911b;
    // background-color: #e4faf2;
  }

  &-unavailable {
    color: #da011c;
    // background-color: #f3d4d4;
  }

  &-partially_available {
    color: #181203;
    // background-color: #b5b8c3;
  }

  &-already_hired {
    color: #181203;
    // background-color: #c9d5ff;
  }
}

.eventTime {
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-size: 7px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  &-available {
    color: #16911b;
    // background-color: #e4faf2;
  }

  &-unavailable {
    color: #da011c;
    // background-color: #f3d4d4;
  }

  &-partially_available {
    color: #181203;
    // background-color: #b5b8c3;
  }

  &-already_hired {
    color: #181203;
    // background-color: #c9d5ff;
  }
}

.redBackground {
  background-color: red;
}

.greenBackground {
  background-color: green;
}

.grayBackground {
  background-color: lightgray;
}

.companyData-top {
  display: flex;
  flex-wrap: wrap;
  // white-space: nowrap;
  column-gap: 12px;
  align-items: center;
}

.chevronDownIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.eventDiv-right {
  cursor: pointer;
  margin-left: 12px;
}

//
// Worker Class

.worker-image {
  width: 24.962px;
  height: 24.962px;
  flex-shrink: 0;
  border-radius: 83.334px;
  @include max-md {
    width: 24.962px;
    height: 24.962px;
  }
}

.workerContainer {
  display: flex;
  align-items: center;
  column-gap: 12px;
  cursor: pointer;
  padding: 5px 0;
  border-width: 0px 0px thin 0px;
  @include max-md {
    column-gap: 8px;
  }
}

.worker-name {
  color: #101828;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
}

.datesContainer-left {
  display: flex;
  width: 200px;
  overflow-x: auto;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100vh - 170px);
  @include max-md{
    width: 100%;
  }
}
.datesContainer-left-loading {
  display: flex;
  width: 200px;
  overflow-x: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 170px);
  @include max-md{
    width: 100%;
  }
}

.pc-left{
  @include max-md{
    display: none;
  }
}

.datesContainer-right {
  width: calc(100% - 230px);
  height: calc(100vh - 170px);
  overflow-x: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  /* Hide the scrollbar for Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }
  @include max-md {
    width: 100%;
  }
}

.workers-col {
  // height: 66%;
  height: calc(100% - 120px);
  // box-shadow: none;
  // height: calc(100% - 150px);
  overflow-y: auto;
  width: 100%;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  /* Hide the scrollbar for Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }
  @include max-md {
    height: auto;
    // height: 80%;
  }
}

.focused-worker{
  background: #9fb5ff;
  border-radius: 8px;
  padding: 8px;
  &-name{
    color: white;
  }
}

