@import "../../styles/breakpoints";

.FinancePage {
  flex-shrink: 0;
  // background: #fff;
  // box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  // height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  padding:20px;
}

.FinancePage-main {
  flex-shrink: 0;
  // height: 100%;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  padding: 24px 32px;
  margin-top: 40px;
  min-height: 343px;
  @include max-md {
    // padding: 16px 0;
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.FinancePage-categories {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  overflow-x: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  /* Hide the scrollbar for Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }
}

.FinancePage-main-heading {
  display: flex;
  column-gap: 12px;
}

.primaryBtn {
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #101828;
  display: inline-flex;
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  height: 30px;
  flex-shrink: 0;
  @include max-md {
    padding: 8px 16px;
  }
}
.secondaryBtn {
  display: inline-flex;
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #fff;
  color: #9fb5ff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  flex-shrink: 0;
  height: 30px;
  @include max-md {
    padding: 8px 16px;
  }
}
.paymentButton {
  display: inline-flex;
  padding: 0px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #fff;
  color: #9fb5ff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  flex-shrink: 0;
  height: 30px;

}

.cateogory {
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #101828;
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  @include max-slg {
    font-size: 12px;
  }
  @include max-md {
    font-size: 10.719px;
    line-height: 15.312px;
    letter-spacing: 0.643px;
  }
}

.title {
  color: #101828;
  //  
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  @include max-slg {
    // font-size: 12px;
  }
  @include max-md {
    font-size: 20px;
    line-height: 32px;
  }
}

.FinancePage-table {
  margin-top: 26px;
  overflow: auto;
  scrollbar-width:thin;
  -ms-overflow-style:inherit;

  /* Hide the scrollbar for Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: flex;
  }
}

.pagination {
}
