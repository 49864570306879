// @use "../../../assets/styles/variables" as v;
// @import "../../../styles/breakpoints";

.actionButton-postListing-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 14px;
}

.dropdown-option {
  display: flex;
  cursor: pointer;
  padding: 16px 4px;
}

.dropdownTxt {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  // @include max-slg{
  //   font-size: 11px;
  // }
  // @include max-md{
  //   display: none;
  // }
}
.dropdown-menu {
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.flagImg {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 48px;
}
/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
