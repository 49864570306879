@import "../../../styles/breakpoints";

.EmployeeRatingAndReviewCard {
  border-radius: 20px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  padding: 26px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  column-gap: 12px;

  @include max-md {
    padding: 24px 20px;
  }
  @include max-sm {
    // margin: 64px 20px;
  }
}

.EmployeeRatingAndReviewCard-main {
  display: flex;
  column-gap: 16px;
}
.title {
  color: #101828;
  // 
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 16px;
  @include max-md {
    margin-top: 24px;
    font-size: 20px;
    line-height: 28px;
  }
}

.name {
  color: #101828;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  @include max-md {
    font-size: 16px;
    line-height: 24px;
  }
}

.role {
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 12px;

    line-height: 16px;
  }
}

.reviewMessage {
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 24px;
  @include max-md {
    font-size: 12px;
    line-height: 24px;
  }
}

.image {
  width: 48px;
 height: 40px;
  flex-shrink: 0;
  border-radius: 400px;
  @include max-md {
    width: 44px;
    height: 44px;
  }
}

.EmployeeRatingAndReviewCard-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include max-md {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 18px;
  }
}
.EmployeeRatingAndReviewCard-left {
  display: flex;
  // flex: 0.1;
  flex-shrink: 0;
  // column-gap: 12px;
}
.EmployeeRatingAndReviewCard-right {
  display: flex;

  flex-direction: column;
  width: 90%;
  margin-left: auto;
}

.EmployeeRatingAndReviewCard-bottom {
  margin-top: 24px;
  width: 90%;
  margin-left: auto;
  @include max-md {
    width: 100%;
  }
}

.ReviewCard-right-rating {
  display: flex;
  align-items: flex-start;
  column-gap: 4px;
}

.ratingText {
  color: #1c53f4;
  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 145.455% */
  text-transform: capitalize;
  margin-right: 4px;
}

.EmployeeRatingAndReviewCard-left-text {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}
