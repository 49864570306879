@import "../../../../../styles/breakpoints";

.JobDetails {
  display: flex;

  justify-content: space-between;

  @include max-md {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  @include max-sm {
    // padding: 16px;
  }
}
.JobDetails-left {
  display: flex;
  width: 67%;
  row-gap: 40px;
  flex-direction: column;

  @include max-md {
    // padding: 32px;
  }
  @include max-sm {
    // padding: 16px;
  }
}
.JobDetails-right {
  display: flex;
  width: 30%;
  row-gap: 40px;
  flex-direction: column;

  @include max-md {
    // padding: 32px;
  }
  @include max-sm {
    // padding: 16px;
  }
}

.JobDetails-location {
  border-radius: 24px;
  background: #fff;

  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  padding: 20px 20px 0px 15px;
}

.dateDiv {
  display: flex;
  // flex-direction: column;
  column-gap: 12px;
  margin-bottom: 34px;
}
.JobDetails-right-similarJobs {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.jobCard {
  width: auto !important;
}

.jobs-date {
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.section-title {
  color: #101828;
  justify-content: flex-start;
  display: flex;

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  @include max-md {
    font-size: 20px;
  }
}

.section-text {
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin: 26px 0;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}

// .JobDetails-section-map {
//   border-radius: 16px;
// }
