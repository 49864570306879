.layout {
  // "flex bg-custom-background"
  display: flex;
  // height: 200vh;
  background-color: rgb(240 240 240 / 1);
}
.layout-body {
  width: 100%;
  overflow-y: scroll;
  position: relative;
  overflow-x: hidden;
  height: 100vh;
  // "w-full overflow-y-auto relative"
}



/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 768px) {
  .layout-body {
    width: 100%;
    overflow-y: hidden;
    position: relative;
    overflow-x: hidden;
    height: auto;
    // "w-full overflow-y-auto relative"
  }
  .layout{
    // height: 110vh;
    overflow-y: hidden;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */ 
@media only screen and (min-width: 600px) {
  .card {
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card {
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card {
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .card {
  }
}
