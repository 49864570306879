.VOGSection {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.VOGSection-main {
  display: flex;
  row-gap: 40px;
  flex-direction: column;
  margin-top: 64px;
}

.detail-field {
  display: flex;
  /* column-gap: 64px; */
  /* justify-content: space-between; */
  row-gap: 16px;
  column-gap: 64px;
  align-items: center;
}
.detail-field-form {
  display: flex;
  /* column-gap: 64px; */
  /* justify-content: space-between; */
  // row-gap: 16px;
  // column-gap: 64px;
  align-items: center;
}

.labelText {
  color: #000;
  text-align: left;
  font-feature-settings: "clig" off, "liga" off;
  // font-family: Noto Sans JP;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  white-space: nowrap;
}

.labelValue {
  color: #808790;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  // font-family: Noto Sans JP;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */
}

.primaryBtn {
  border-radius: 6px;
  background: #9fb5ff !important;
  display: flex;
  width: 300px !important;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: "Monsterrat";
  color: black;
}

.documentImg {
  width: 265px;
  height: 312px;
  flex-shrink: 0;
  object-fit: contain;
}

.labelValue_response {
  color: #f00;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  // font-family: Noto Sans JP;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 110% */
}

.disableBtn {
  display: flex;
  width: 380px !important;
  height: 87px;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 6px;

  background: lightgray !important;
  font-size: 20px;
  font-weight: 700;

  color: #000;
}
.allowEmployeeBtn {
  display: flex;
  width: 380px !important;
  height: 87px;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 6px;

  background: #00ff1a !important;
  font-size: 20px;
  font-weight: 700;

  color: #000;
}

.declineEmployeeBtn {
  display: flex;
  width: 380px !important;
  height: 87px;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #e30000 !important;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}
/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
