@import "../../../styles/breakpoints";

.JobCard {
  width: 400px;
  // height: 400px;
  // width: 448px;
  // height: 424px;
  flex-shrink: 0;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  @include max-slg {
    padding: 16px;
    // width: 275px;
    // height: 375px;
    // width: 308px;
  }
  @include max-md {
    padding: 16px;
    width: 320px;
    // height: 324.625px;
  }
  @include max-sm {
    padding: 16px;
    // width: 343px;
    // height: 324.625px;
  }
  @include max-xs {
    width: 300px;
  }
}

.city {
  color: #1c53f4;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 10.719px;
    line-height: 15.313px;
  }
}
.country {
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 10.719px;
    line-height: 15.313px;
  }
}

.JobCard-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.JobCard-mid-top {
  display: flex;
  column-gap: 8px;
}

.JobCard-top-left {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.JobCard-mid {
  display: flex;
  // height: 70%;
  flex-direction: column;
  row-gap: 16px;
  justify-content: space-evenly;
  margin-top: 32px;
}

.JobCard-mid-details {
  display: flex;
  column-gap: 4px;
  align-items: center;
}

.JobCard-mid-bottom {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.JobCard-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.JobCard-bottom-left {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
.promoted {
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #9fb5ff;
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  @include max-slg {
    font-size: 12px;
  }
  @include max-md {
    font-size: 11.325px;
    line-height: 16.179px; /* 142.857% */
    letter-spacing: 0.68px;
  }
}
.urgent {
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #dc2d2d;
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  @include max-slg {
    font-size: 12px;
  }
  @include max-md {
    font-size: 11.325px;
    line-height: 16.179px; /* 142.857% */
    letter-spacing: 0.68px;
  }
}

.cateogory {
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #101828;
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  @include max-slg {
    font-size: 12px;
  }
  @include max-md {
    font-size: 10.719px;
    line-height: 15.312px;
    letter-spacing: 0.643px;
  }
}

.title {
  color: #101828;
   
  font-size: 20px;
  // font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.details {
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
  @include max-slg {
    font-size: 12px;
    line-height: 20px;
  }
  @include max-md {
    font-size: 10.719px;
    line-height: 15.312px;
  }
}

.companyName {
  color: #101828;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  @include max-slg {
    font-size: 14px;
    line-height: 28px;
  }
  @include max-md {
    font-size: 13.781px;
    line-height: 21.438px;
  }
}

.rate {
  color: #1c53f4;
  font-family: Open Sans;
  font-size: 20px;
  // font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  @include max-slg {
    font-size: 20px;
    line-height: 28px;
  }
  @include max-md {
    font-size: 18.375px;
    line-height: 24.5px;
  }
}

.companyLogo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  @include max-slg {
    width: 48px;
   height: 48px;
  }
  @include max-md {
    width: 36.75px;
    height: 36.75px;
  }
}

.proposalSubmitted {
  color: #05cf69;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 11.325px;
    line-height: 16.179px;
  }
}

.invitedImage {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  border-radius: 28px;
  object-fit: cover;
  @include max-md {
    width: 22.651px;
    height: 22.651px;
  }
}

.invitedText {
  color: #75808f;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  column-gap: 6px;
  @include max-md {
    font-size: 9.708px;
    line-height: 14.561px;
  }
}

.invitedName {
  color: #101828;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  @include max-md {
    font-size: 9.708px;
    line-height: 14.561px;
  }
}

.invitedDiv {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  align-items: center;
}

.cancelJob-text {
  color: #ea4335;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 33px;
  cursor: pointer;
  @include max-md {
  }
}

.repostJob-text {
  color: #1c53f4;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 33px;
  cursor: pointer;
  @include max-md {
  }
}

.JobCard-bottom-btns {
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
}


.select-primary{
  border-radius: 80px;
  border: 1.5px solid;
  color: #000;
  display: inline-flex;
  padding: 0px 5px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  font-weight: 600;
  width: 93px;
  height: 30px;

  column-gap: 8px;
  flex-shrink: 0;
  transition: 0.3s ease-in-out;

  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}

.under_review {
  // background color should be yellow 
  background: #FFD700 !important;
  color: #000 !important;
}

.admin{
  background: #c9d5ff !important;
  color: #fff !important;
}

.users{
  background: green !important;
  color: #fff !important;
}
.deleted{
  background: red !important;
  color: #fff !important;
}