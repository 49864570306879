@import "../../../styles/breakpoints";

.InviteWorkerModal {
  display: flex;
  flex-direction: column;
  background: #fff;
  // height: 628px;
  // width: 520px;
  align-items: center;
  width: 400px;
  overflow-y: auto;

  @include max-md {
    width: auto;
    height: auto;
  }
  @include max-sm {
    // margin-top: 60px;
  }
}
.InviteWorkerModal-main {
  width: 100%;
}
.InviteWorkerModal-head {
  display: flex;
  column-gap: 16px;
  align-items: center;
  width: max-content;
  cursor: pointer;
  margin-bottom: 40px;
  width: 100%;
  justify-content: center;
}

.backText {
  color: #101828;

  text-align: right;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.workerData-sections {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.buttonDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  margin-top: 20px;
}
