@import "../../../styles/breakpoints";

.uploadCard {
  border-radius: 20px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  padding: 16px;
  cursor: pointer;
  transition: border-color 0.3s ease;

  width: 240px;
  height: 108px;
  flex-shrink: 0;

  @include max-slg {
    width: 240px;
    height: 108px;
  }
  @include max-md {
    width: 240px;
    height: 108px;
    padding: 12px 10px;
  }
  @include max-sm {
    width: 164px;
    height: 96px;
    padding: 12px 10px;
  }

  &.selected {
    border-radius: 20px;
    border: 3px solid #0f1322;
  }

  .modalFiles{
    height: 600px;
    width: 600px;
  }

  // Add other styling for the card
  // ...

  .card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    color: #0f1322;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    @include max-sm {
      font-size: 12px;
      line-height: 20px;
    }
  }
  .text {
    color: #858d98;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    @include max-sm {
      font-size: 10px;
      line-height: 20px;
    }
  }
}

.drop-zone {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.text-icon {
  display: flex;
  column-gap: 12px;
  align-items: center;
}

.uploadImg {
  width: 44px;
  height: 44px;
  object-fit: cover;
  border-radius: 12px;
}

.deleteText {
  color: #f71735;
  text-align: right;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}


.slide{
  &-pdf{
    width: 70px;
    height: 70px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &-image{
    width:300px;
    height:150px;
  }
}

.title {
  color: #0f1322;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  @include max-sm {
    font-size: 12px;
    line-height: 20px;
  }
}

.Image-styling{
  width: 300px;
  height: 220px;
  object-fit: cover;
  border-radius: 12px;
}