@import "../../../styles/breakpoints";

.EventCard {
  // margin-bottom: 33px;
  @include max-md {
    // margin-bottom: 20px;
  }
}
.eventsContainer {
  color: #858d98;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include max-md {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.eventsContainer-body {
  min-height: 45px;
  // height: 45px;
  padding: 1.75px 12px;
  border-radius: 6px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // max-width: 220px;
  &-available {
    background-color: #e4faf2;
    border-left: 8px solid #16911b;
  }

  &-unavailable {
    background-color: #f3d4d4;
    border-left: 8px solid #da011c;
  }

  &-partial {
    background-color: #b5b8c3;
    border-left: 8px solid #181203;
  }

  &-already_hired {
    background-color: #c9d5ff;
    border-left: 8px solid #181203;
  }


  &-hired{
    background-color: #cbf6d9;
    border-left: 8px solid #009633;
  }
  
  &-rejected{
    background-color: #edb4bb;
    border-left: 8px solid #DA011C;
  }
  
  &-completed{
    background-color: #e1e5f6;
    border-left: 8px solid #C9D5FF;
  }
  

  
}

.eventsContainer-body-popover {
  min-height: 45px;
  // height: 45px;
  padding: 1.75px 12px;
  border-radius: 6px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 220px;
  &-available {
    background-color: #e4faf2;
  }

  &-unavailable {
    background-color: #f3d4d4;
  }

  &-partial {
    background-color: #b5b8c3;
  }

  &-already_hired {
    background-color: #c9d5ff;
  }
}

.eventsContainer-body-head {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
  margin-top: 10px;
  column-gap: 12px;
}

.eventTitle {
  //font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  // margin-bottom: 6px;

  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &-available {
    color: #16911b;
    // background-color: #e4faf2;
  }
  &-hired {
    color: #16911b;
    // background-color: #e4faf2;
  }

  &-unavailable {
    color: #da011c;
    // background-color: #f3d4d4;
  }
  &-rejected {
    color: #da011c;
    // background-color: #f3d4d4;
  }

  &-partial {
    color: #181203;
    // background-color: #b5b8c3;
  }

  &-already_hired {
    color: #181203;
    // background-color: #c9d5ff;
  }


  &-completed{
    color: #000;
  }


}



.eventTime {
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-size: 7px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  &-available {
    color: #16911b;
    // background-color: #e4faf2;
  }
  &-hired {
    color: #16911b;
    // background-color: #e4faf2;
  }

  &-rejected {
    color: #da011c;
    // background-color: #f3d4d4;
  }
  &-unavailable {
    color: #da011c;
    // background-color: #f3d4d4;
  }

  &-partial {
    color: #181203;
    // background-color: #b5b8c3;
  }
  &-completed {
    color: #181203;
    // background-color: #b5b8c3;
  }

  &-already_hired {
    color: #181203;
    // background-color: #c9d5ff;
  }


}

.redBackground {
  background-color: red;
}

.greenBackground {
  background-color: green;
}

.grayBackground {
  background-color: lightgray;
}

.companyData-top {
  display: flex;
  flex-wrap: wrap;
  // white-space: nowrap;
  column-gap: 12px;
  align-items: center;
}

.chevronDownIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.eventDiv-right {
  cursor: pointer;
  margin-left: 12px;
}
