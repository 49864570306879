@use "../../../assets/styles/variables" as v;
@import "../../../styles/breakpoints";

.actionButton-postListing-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 6px;
  margin-right: 20px;
}

.dropdown-option {
  display: flex;
  cursor: pointer;
  padding: 16px 4px;
  &:hover {
    background: #f9fafb;
    border-radius: 8px;
    border: 1.5px solid v.$color-border-btn;
    background: v.$btn-active-bg;
    color: v.$primary-text;
  }
}

.dropdownTxt {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  @include max-slg{
    font-size: 11px;
  }
  @include max-md{
    display: none;
  }
}
.dropdown-menu {
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  display:flex;
  flex-direction: row;
}
/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
