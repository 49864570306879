@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "NotoSans-Light";
  src: local("NotoSans-Regular"),
    url("./assets/fonts/NotoSans/NotoSans-Regular.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "NotoSans-Regular";
  src: local("NotoSans-Regular"),
    url("./assets/fonts/NotoSans/NotoSans-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "NotoSans-Medium";
  src: local("NotoSans-Regular"),
    url("./assets/fonts/NotoSans/NotoSans-Regular.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "NotoSans-SemiBold";
  src: local("NotoSans-Bold"),
    url("./assets/fonts/NotoSans/NotoSans-Bold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "NotoSans-Bold";
  src: local("NotoSans-Bold"),
    url("./assets/fonts/NotoSans/NotoSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* //Poppins */
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Regular"),
    url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-Bold"),
    url("./assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("./assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* //Open Sans */
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans-Regular"),
    url("./assets/fonts/Open_Sans/static/OpenSans-Regular.ttf")
      format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Open Sans-Medium";
  src: local("Open Sans-Regular"),
    url("./assets/fonts/Open_Sans/static/OpenSans-Regular.ttf")
      format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Open Sans-SemiBold";
  src: local("Open Sans-Bold"),
    url("./assets/fonts/Open_Sans/static/OpenSans-Bold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Open Sans-Bold";
  src: local("Open Sans-Bold"),
    url("./assets/fonts/Open_Sans/static/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* //Monsterrat */

@font-face {
  font-family: "Monsterrat";
  src: local("Monsterrat-Bold"),
    url("./assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf")
      format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Monsterrat-Bold";
  src: local("Monsterrat-Bold"),
    url("./assets/fonts/Montserrat/static/Montserrat-Bold.ttf")
      format("truetype");
  font-weight: 700;
}

body {
  margin: 0;
  font-family: "Open Sans";
  overflow: hidden;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(240 240 240 / 1);
}

code {
  font-family: "NotoSans-Regular";
  -webkit-font-smoothing: antialiased;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}
