@import "../../../styles/breakpoints";

.SkillAndExpertiseCard {
  // margin: 64px 32px;
  height: auto;
  flex-shrink: 0;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  padding: 20px 20px 0px 15px;

  @include max-md {
    padding: 24px 20px;
  }
}

.header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: #101828;
  // 
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 24px;
  @include max-sm {
    font-size: 20px;
    line-height: 28px;
  }
}
.skill {
  border-radius: 8px;
  border: 1.5px solid rgba(201, 213, 255, 0.4);
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  column-gap: 24px;
  @include max-md {
    font-size: 10.719px;
    line-height: 15.313px; /* 142.857% */
    letter-spacing: 0.643px;
    padding: 4.593px 9.188px 3.907px 9.187px;
  }
}

.SkillAndExpertiseCard-skills {
  display: flex;
  column-gap: 12px;
  row-gap: 12px;
  flex-wrap: wrap;
}

.see-more {
  color: #1c53f4;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-top: 16px;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}
