.OrganizationDetailsCard {
  border-radius: 24px;
  background: #fff;
  padding: 32px;
}

.OrganizationDetailsCard-top {
  display: flex;
  // align-items: center;
  justify-content: center;
}
.userImg {
  width: 168px;
  height: 168px;
  flex-shrink: 0;
  border-radius: 50%;
}

.OrganizationDetailsCard-mid {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  row-gap: 24px;
  margin-top: 64px;
}

.OrganizationDetailsCard-bottom {
  display: flex;
  row-gap: 24px;
  flex-direction: column;
  margin-top: 64px;
}

.detail-field {
  display: flex;
  /* column-gap: 64px; */
  /* width: 355px; */
  justify-content: space-between;
  align-items: center;
  row-gap: 16px;
  column-gap: 16px;
}

.labelText {
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  // font-family: Noto Sans JP;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  white-space: nowrap;
}

.labelValue {
  color: #808790;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  // font-family: Noto Sans JP;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */
  max-width: 250px;
}

.labelValue-address {
  color: #808790;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  // font-family: Noto Sans JP;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */
  max-width: 250px;
  text-align: left;
}

.collapsible-label {
  display: flex;
  align-items: center;
  column-gap: 12px;
  justify-content: space-between;
}

.documentImg {
  width: 154px;
  height: 93px;
  flex-shrink: 0;
  object-fit: cover;
}

.detail-field-documents {
  display: flex;
  /* column-gap: 64px; */
  width: 80%;
  align-items: center;
  row-gap: 16px;
}
.detail-field-document {
  display: flex;
  column-gap: 24px;
  row-gap: 16px;
  align-items: center;
  margin: 0 16px;
}
/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}


.address-select{
  width:200px;
}