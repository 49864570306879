@use "../../../assets//styles/variables" as v;
@import "../../../styles/breakpoints";
// @use "../../../assets/styles/variables" as v;


.InviteWorker-main-workers {
  padding: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  @include max-md {
    padding: 16px;
  }
}


.InviteWorkerCard {
  // margin: 64px 32px;
  flex-shrink: 0;
  border-radius: 24px;
  background: #fff;
  // box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);


  @include max-md {
    display: flex;
    flex-direction: column;
    height: auto;
  }
}
.InviteWorkerCard-top {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid rgba(28, 83, 244, 0.06);
  padding-bottom: 18px;
}
.InviteWorkerCard-mid {
  display: flex;

  border-bottom: 2px solid rgba(28, 83, 244, 0.06);
  padding: 20px 37px;
}

.InviteWorkerCard-bottom {
  display: flex;
  border-bottom: 2px solid rgba(28, 83, 244, 0.06);
  padding: 10px 10px;
  justify-content: space-between;
}

// .InviteWorkerCard-bottom-left {
//   flex: 0.5;
//   display: flex;
//   column-gap: 64px;
// }
// .InviteWorkerCard-bottom-right {
//   flex: 0.5;
//   display: flex;
//   justify-content: center;
// }

.InviteWorkerCard-left {
  display: flex;
  column-gap: 24px;
}
.InviteWorkerCard-left-mid {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* row-gap: 24px; */
  justify-content: space-between;
  @include max-md {
    align-items: center;
    margin-top: 24px;
  }
}
.InviteWorkerCard-left-right {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 10px;
  justify-content: center;
  @include max-md {
    align-items: center;
    margin-top: 24px;
  }
}
.button-div {
  display: flex;
  column-gap: 16px;
}

.left-details-iconText {
  display: flex;
  column-gap: 8px;
  align-items: center;
  @include max-md {
    margin-bottom: 20px;
  }
}

.left-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include max-md {
    row-gap: 14px;
    width: 100%;
  }
}

.left-details-top {
  display: flex;
  flex-direction: column;
  @include max-md {
    row-gap: 2px;
  }
}

.left-details-bottom {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @include max-md {
    row-gap: 12px;
  }
}
.left-image {
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
}
.workerImage {
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  border-radius: 80px;

  @include max-md {
    width: 48px;
   height: 40px;
  }
}

.title {
  color: #101828;
  // // 
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  cursor: pointer;
  @include max-md {
    line-height: 28px;
  }
}

.companyName {
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  @include max-md {
    font-size: 10px;
    line-height: 12px;
  }
}

.description {
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 10px;
    line-height: 16px;
  }
}

.city {
  color: #1c53f4;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 10px;
    line-height: 16px;
  }
}

.rate {
  color: #1c53f4;
  text-align: right;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  @include max-md {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
  }
}

.available {
  border-radius: 8px;
  background: #e7ffe1;
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;

  @include max-md {
    font-size: 10px;
    line-height: 16px;
  }
}

.available-text {
  color: #26c700;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 12px;
    line-height: 16px;
  }
}
.topRated {
  border-radius: 8px;
  background: #101828;
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  column-gap: 4px;

  @include max-md {
    font-size: 10px;
    line-height: 16px;
  }
}
.topRated-text {
  color: #fff;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  @include max-md {
    font-size: 12px;
    line-height: 16px;
  }
}

.availableDiv {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  @include max-md {
    margin-bottom: 12px;
  }
}

.socialIcon-div {
  border-radius: 80px;
  background: rgba(201, 213, 255, 0.2);
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.InviteWorkerCard-right-below {
  display: flex;
  column-gap: 24px;
}

.available-dot {
  position: absolute;
  right: 0px;
  position: absolute;
  top: 35%;
  @include max-sm {
    top: 50%;
  }
}

.left-details-top-main {
  display: flex;
  align-items: center;
  column-gap: 19px;
  @include max-md {
    justify-content: space-between;
  }
}

.left-details-top-rating {
  border-radius: 32px;
  background: rgba(241, 245, 254, 0.5);
  display: inline-flex;
  height: 32px;
  padding: 8px 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
}

.rating-text {
  color: #1c53f4;
  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 145.455% */
  text-transform: capitalize;
}

.InviteWorkerCard-right-buttons {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.userDescription {
  color: #75808f;

  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;

  // only 4 lines

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;


}

.primary-active {
  border-radius: 10px;
  border: 1.5px solid v.$color-border-btn;
  background: v.$btn-active-bg;
  color: v.$primary-text;
  display: inline-flex;
  padding: 7px;
  height: 40px;
  width: 114px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  column-gap: 8px;
  flex-shrink: 0;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}

.secondary-active {
  border-radius: 10px;
  border: 1px solid #101828;
  background: #101828;
  color: #fff;
  display: inline-flex;
  height: 40px;
  width: 114px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  cursor: pointer;
  font-weight: 600;
  column-gap: 8px;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}

.skill {
  display: flex;
  width: max-content;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1.5px solid rgba(201, 213, 255, 0.4);

  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  @include max-md {
  }
}

.WorkerCard-mid-skills {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 8px;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
  @include max-md {
  }
}

.InviteWorkerCard-mid-below {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
}
