// @use "../../../assets/styles/variables" as v;
// @import "../../../styles/breakpoints";

.actionButton-postListing-icon {
  display: flex;
  cursor: pointer;
  column-gap: 14px;
  align-items: center;
  border-radius: 8px;
  background: var(--supporting-color-yellow-shade, #fffaf1);
  width: 52px;
  height: 46px;
  // @include max-slg {
  //   column-gap: 4px;
  // }
  // @include max-md {
  //   column-gap: 14px;
  // }
}

.dropdown-option {
  display: flex;
  cursor: pointer;
  padding: 16px 4px;
  column-gap: 12px;
  z-index: 100;
  align-items: center;
  // @include max-slg {
  //   column-gap: 4px;
  // }
  // @include max-md {
  //   column-gap: 12px;
  // }
}

.dropdown-menu {
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  // @include max-slg {
  //   font-size: 11px;
  // }
  // @include max-md {
  //   font-size: 14px;
  // }
}

.title {
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.markRead {
  color: #9fb5ff;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.seeAllNotifications {
  color: #1c53f4;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.dropdown-option-top {
  border-bottom: 2px solid rgba(28, 83, 244, 0.06);
  padding: 12px;
  display: flex;
  justify-content: space-between;
}

.dropdown-option-mid {
  border-bottom: 2px solid rgba(28, 83, 244, 0.06);
  padding: 0 8px;
  margin: 0 !important;
}

.dropdown-option-bottom {
  padding: 4px 12px;
}

.notification-icon {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.notification-alert {
  position: absolute;
  top: 10%;
  right: 10%;
}

.notificationDiv {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  align-items: center;
}
.notifications {
  max-height: 330px;
  overflow-y: auto;
  padding: 8px 0;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  /* Hide the scrollbar for Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }
}

.image {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 50%;
}

.title {
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: left;

  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.time {
  color: var(--gray-400, #7a8699);
  font-feature-settings: "clig" off, "liga" off;
  // font-family: "Inter";
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
