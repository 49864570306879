.card {
  background-color: white;
  border-radius: 16px;
  width: 500px;
  height: 650px;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  // bg-white rounded-2xl w-[345px] lg:w-[500px] lg:h-[650px] lg:space-y-5 lg:px-10 relative flex items-center cardReponsive

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem 0.5rem;

  // justify-center flex px-2 flex-col py-10 w-full
}

.seeMoreButton {
  border-radius: 10px;
  background: #9fb5ff;
  display: flex;
  width: 172.528px;
  height: 38px;
  flex-shrink: 0;
  font-weight: 600;
  align-items: center;
  justify-content: center;
}

/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 600px) {
  .card {
    width: 310px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 500px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card {
    width: 345px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 500px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card {
    width: 345px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 500px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card {
    width: 500px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    height: 650px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .card {
    width: 500px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    height: 650px;
  }
}

.filter-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  justify-content: center;

  margin-right: 20px;


}

.filter-title {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  justify-content: center;




  color: #000;
  display: inline-flex;
  padding: 15px 20px;

  font-size: 13px;
  cursor: pointer;
  font-weight: 600;
  min-width: 60px;


  column-gap: 8px;
  flex-shrink: 0;



}

.radio{

  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #000;
  cursor: pointer;
}

 

 
