@import "../../styles/breakpoints";

.AccountPage {
  flex-shrink: 0;
  // background: #fff;
  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  // height: calc(100vh - 250px);
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  padding: 48px;
  @include max-md {
    padding: 32px;
    box-shadow: none;
    height: auto;
  }
  @include max-sm {
    padding: 16px;
  }
}

.AccountPage-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ProfileSectionCard-tabs {
  display: flex;
  flex-direction: column;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include max-md {
    flex-direction: row;
    column-gap: 20px;
    overflow-y: auto;
    padding: 18px 0;
  }
}

.AccountPage-main {
  display: flex;
  padding: 40px 0px 40px 0;
  justify-content: space-between;
  height: 100%;
  @include min-3xl{
    max-width: 1200px;
     align-self: center;
     }
  @include max-md {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.side-item {
  color: #75808f;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}

.AccountPage-left {
  border-right: 2px solid rgba(201, 213, 255, 0.3);
  width: 25%;
  display: flex;
  flex-direction: column;
  @include max-md {
    width: 100%;
    border: none;
    display: flex;
    // flex-direction: row;
    column-gap: 32px;
  }
}
.AccountPage-right {
  width: 100%;
  // overflow-y: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
  @include max-md {
    width: 100%;
  }
}

.AccountPage-right-section {
  display: flex;
  // row-gap: 24px;
  flex-direction: column;
  border-radius: 24px;
  border: 2px solid #e7f1f8;
  background: #fff;
  padding: 40px 48px;
  margin-top: 32px;
  row-gap: 24px;
  @include max-md {
    padding: 24px 20px;
  }
  @include max-sm {
    padding: 24px 20px;
  }
}
.AccountPage-right-section-map {
  display: flex;
  // row-gap: 24px;
  flex-direction: column;

  border-radius: 24px;
  border: 2px solid #e7f1f8;
  background: #fff;
  padding: 40px 48px;
  margin-top: 32px;
  row-gap: 24px;
  @include max-md {
    padding: 24px 20px;
    // row-gap: 0;
  }
  @include max-sm {
    padding: 24px 20px;
    // row-gap: 0;
  }
}

.AccountPage-right-section-job {
  display: flex;
  // row-gap: 24px;
  flex-direction: column;
  border-radius: 24px;
  border: 2px solid #e7f1f8;
  background: #fff;
  padding: 40px 48px;
  margin-top: 32px;
  row-gap: 24px;
  @include max-md {
    padding: 24px 20px;
    row-gap: 12px;
  }
  @include max-sm {
    padding: 24px 20px;
  }
}

.AccountPage-right-section-profile {
  display: flex;
  border-radius: 24px;
  border: 2px solid #e7f1f8;
  background: #fff;
  padding: 40px 48px;
  margin-top: 32px;
  align-items: center;
  justify-content: space-between;
  @include max-md {
    padding: 24px 20px;
    margin-top: 0;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 24px;
  }
  @include max-sm {
    padding: 20px 16px;
  }
}

.AccountPage-right-section-branchAndCategory {
  display: flex;
  // row-gap: 24px;
  flex-direction: column;
  border-radius: 24px;
  border: 2px solid #e7f1f8;
  background: #fff;
  padding: 40px 48px;
  margin-top: 32px;
  row-gap: 40px;
  @include max-md {
    padding: 20px 16px;
    row-gap: 20px;
  }
  @include max-sm {
    padding: 20px 16px;
  }
}

.title {
  color: #101828;
  //
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  @include max-md {
    font-size: 24px;
    line-height: 32px;
  }
}

.left-item {
  display: flex;
  margin-bottom: 32px;
  column-gap: 12px;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  @include max-md {
    column-gap: 4px;
    margin-bottom: 8px;
  }
}
.left-item-social {
  display: flex;
  margin-bottom: 32px;
  column-gap: 12px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  border: 2px solid #e7f1f8;
  min-height: 72px;
  background: #fff;
  cursor: pointer;
  padding: 0 24px;
  justify-content: space-between;
  width: 85%;

  @include max-md {
    column-gap: 4px;
    margin-bottom: 8px;
  }
}

.profileSection {
  padding: 12px 16px !important;
  width: 260px !important;
  @include max-md {
    width: 100% !important;
  }
}

.section-profile-left {
  display: flex;
  align-items: center;
  column-gap: 24px;
  @include max-md {
    column-gap: 16px;
  }
}

.ProfileSectionCard-progress {
  width: 100%;
  margin-bottom: 48px;
  @include max-md {
    margin-bottom: 25px;
  }
}

.section-title {
  color: #101828;
  text-align: left;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  @include max-md {
    font-size: 20px;
    line-height: 32px;
  }
}

.uploadImg-div {
  position: relative;
  width: 100px;
  height: 100px;
  @include max-md {
    width: 56px;
    height: 56px;
  }
}

.uploadImg {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 60px;
  @include max-md {
    width: 56px;
    height: 56px;
  }
}

.editIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  background: #fff;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include max-md {
    width: 16px;
    height: 16px;
  }
}

.avatar {
  color: #101828;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  @include max-md {
    font-size: 14px;
    line-height: 20px;
  }
}

.avatarSubtitle {
  color: #667085;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  @include max-md {
    font-size: 10px;
    line-height: 16px;
  }
}

.selectInputAvailability {
  padding: 12px 16px 12px 20px;
  height: 56px !important;
  display: flex;
  width: 90%;
  // width: 214px;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  align-items: center;
  @include max-md {
    width: 150px;
    height: 40px !important;
  }
}

.selectInputAvailability > div > div > div {
  @include max-md {
    color: #101828;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 0 !important;
  }
}
.selectInputAvailability > div > div > div > svg {
  @include max-md {
    width: 16px;
    height: 16px;
  }
}

.selectInput > div > div > div {
  @include max-md {
    color: #101828;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 0 !important;
  }
}
.selectInput > div > div > div > svg {
  @include max-md {
    width: 16px;
    height: 16px;
  }
}

.selectInput {
  padding: 12px 16px 12px 20px;
  height: 56px !important;
  display: flex;
  width: 95%;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  align-items: center;
  @include max-md {
    width: 100%;
    height: 48px !important;
  }
}

.selectInput > div {
  background-color: #fff !important;
}

.inputField {
  height: 56px;
  display: flex;
  align-items: center;
  width: 100%;
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  &::placeholder {
    color: #101828;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}

.inputField-wrap > input {
  @include max-slg {
    // margin-top: 20px;
  }
  @include max-md {
    // margin-top: 38px;
    padding-left: 0;
  }
}

.inputField-wrap-full {
  column-gap: 10px;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  align-items: center;
  padding-left: 24px;
  width: 100%;

  @include max-slg {
    // margin-top: 20px;
  }
  @include max-md {
    // margin-top: 38px;
    width: 100%;
    column-gap: 6px;
    height: 40px;
  }
}
.inputField-wrap {
  column-gap: 10px;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  align-items: center;
  padding-left: 24px;
  width: 95%;

  @include max-slg {
    // margin-top: 20px;
  }
  @include max-md {
    // margin-top: 38px;
    width: 100%;
    column-gap: 6px;
    height: 40px;
  }
}

.inputField-wrap-disabled {
  opacity: 0.6;
  column-gap: 10px;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  align-items: center;
  padding-left: 24px;
  width: 95%;

  @include max-slg {
    // margin-top: 20px;
  }
  @include max-md {
    // margin-top: 38px;
    width: 100%;
    column-gap: 6px;
    height: 40px;
  }
}

.inputField-wrap-password {
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 16px;
  width: 95%;
  @include max-slg {
    // margin-top: 20px;
  }
  @include max-md {
    width: 100%;
    column-gap: 6px;
    height: 40px;
  }
}

.profile-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 24px;
  column-gap: 24px;

  @include max-slg {
    display: flex;
    flex-direction: column;
    column-gap: 0px;
  }

  @include max-md {
    display: flex;
    flex-direction: column;
    column-gap: 0px;
  }
}

// .profile-fields > div {
//   // width: 95%;
//   width: 95%;

//   @include max-2xl {
//     width: 360px;
//   }
//   @include max-xlg {
//     width: 300px;
//   }
//   @include max-slg {
//     width: 260px;
//   }
//   @include max-lg {
//     width: 240px;
//   }
//   @include max-md {
//     width: auto;
//   }
// }

.largeDiv {
  width: 97%;
  @include max-md {
    width: 95%;
  }
}

.textArea {
  // height: 332px;
  padding: 18px 24px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1.5px solid rgba(201, 213, 255, 0.4);
  background: #fff;
  margin-top: 24px;
  margin-bottom: 32px;
  width: 100%;
  height: 140px;
  &::placeholder {
    color: #101828;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  @include max-md {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
.textArea:focus {
  border: 1.5px solid rgba(201, 213, 255, 0.4);
}

.right-section-location {
  margin-top: 48px;
}

.right-section-jobAndEducation {
  margin-top: 48px;
}

.right-section-branchesAndCategories {
  margin-top: 48px;
}

.right-section-skillAndInterest {
  margin-top: 48px;
}

.right-section-skillAndInterest {
  margin-top: 48px;
}
.right-section-bank {
  margin-top: 48px;
}

.right-section {
  margin-top: 48px;
}

.attribute {
  height: 40px;
  flex-shrink: 0;
  border-radius: 80px;
  background: rgba(201, 213, 255, 0.3);
  color: #1c53f4;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  width: max-content;
  align-items: center;
  padding: 14px 28px;
  position: relative;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
    height: 40px;
    padding: 10px 20px;
  }
}

.attributeLarge {
  //height: 40px;
  width: 100%;
  flex-shrink: 0;
  border-radius: 40px;
  background: rgba(201, 213, 255, 0.3);
  color: #1c53f4;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 28px;
  position: relative;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
    height: 40px;
    padding: 10px 20px;
  }
}
.attributesSection {
  display: flex;
  column-gap: 16px;
  row-gap: 10px;
  flex-wrap: wrap;
}
.attributesDiv {
  display: flex;
  column-gap: 16px;
  row-gap: 10px;
  flex-wrap: wrap;
}
.deleteAttribute {
  position: absolute;
  top: -4px;
  right: -6px;
  cursor: pointer;
}

.eachFieldWithCategory {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  @include max-md {
    row-gap: 10px;
  }
}

.selected {
  color: #101828;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}

.save-mobile {
  margin-top: 48px;
}
.save-desktop {
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
}

.formButton {
  display: none;
}
.formDiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two items in one row */
  grid-gap: 24px;
  // row-gap: 24px;
  @include max-xlg {
    grid-template-columns: repeat(1, 1fr); /* Two items in one row */
    grid-gap: 24px;
  }
}

.uploadCardDiv {
  width: 356px !important;
  height: 80px !important;
  @include max-xlg {
    width: 100% !important;
  }
}
.uploadCardDiv-inputDiv {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
}

.selectDiv {
  width: 100%;
  // margin-top: 20px;
}

.uploadCard_image {
  width: 48px !important;
  height: 40px;
}

.autoComplete {
  column-gap: 10px;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 16px;
  height: 40px;
  color: #101828;

  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  @include max-slg {
    // margin-top: 20px;
  }
  @include max-md {
    // margin-top: 38px;
  }
}

.AddJobModal-sections {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  row-gap: 32px;
  width: 100%;
}

.eachSection-date {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.section-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.socialIcon-div {
  border-radius: 80px;
  background: rgba(201, 213, 255, 0.2);
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
}
