.actionBtn {
  border-radius: 6px;
  background: #c9d5ff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  // width: 410px;
  height: 35px;
  padding: 0px 10px;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
}

.text {
  color: #101828;

  text-align: center;
  /* Title 20/Bold */
  font-family: "Monsterrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
