@import "../../../../../../styles/breakpoints";

.ContactUsCard {
  // margin: 64px 32px;
  // height: 504px;

  flex-shrink: 0;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  // justify-content: space-between;
  padding: 15px;
  display: flex;
  flex-direction: column;
  @include max-sm {
    // margin: 64px 20px;
  }
}

.title {
  color: #101828;
   
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  line-height: 30px;
  @include max-sm {
    font-size: 21.894px;
    line-height: 27.367px;
  }
}

.contactDiv {
  display: flex;
  column-gap: 12px;
  margin-top: 34px;
  align-items: center;
  @include max-sm {
    margin-top: 30px;
  }
}
.contactNumber {
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  @include max-sm {
    font-size: 12.771px;
    line-height: 18.245px;
  }
}
