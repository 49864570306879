@import "../../styles/breakpoints";

.WorkerDetails {
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  // justify-content: space-between;

  @include max-lg {
    // display: flex;
    // flex-direction: column;
    // row-gap: 24px;
  }
  @include max-sm {
    // padding: 16px;
  }
}

.WorkerDetails-main {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-direction: column;
  width: 100%;

  @include max-md {
    display: flex;
    row-gap: 24px;
    flex-direction: column;
  }
  @include max-sm {
    // padding: 16px;
  }
}

.WorkerDetails-left {
  display: flex;
  // width: 67%;
  row-gap: 40px;
  flex: 1;
  flex-direction: column;

  @include max-md {
    // padding: 32px;
  }
  @include max-sm {
    // padding: 16px;
  }
}
.WorkerDetails-left-reduced {
  display: flex;
  // width: 67%;
  row-gap: 40px;
  flex: 0.67;
  flex-direction: column;

  @include max-md {
    // padding: 32px;
  }
  @include max-sm {
    // padding: 16px;
  }
}
.WorkerDetails-right {
  display: flex;
  // width: 30%;
  flex: 0.3;
  row-gap: 40px;
  flex-direction: column;

  @include max-md {
    // padding: 32px;
  }
  @include max-sm {
    // padding: 16px;
  }
}

.dateDiv {
  display: flex;
  // flex-direction: column;
  column-gap: 12px;
  margin-bottom: 34px;
}
.WorkerDetails-right-similarJobs {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.jobCard {
  width: auto !important;
}

.jobs-date {
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
