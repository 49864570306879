@import "../../styles/breakpoints";

.OrganizationSchedule {
  flex-shrink: 0;
  // background: #fff;
  // box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  justify-content: space-between;
  // padding: 48px;
  @include max-md {
    // padding: 32px;
    height: calc(100vh - 100px);
  }
  @include max-sm {
    // padding: 16px;
  }
}
