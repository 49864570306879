@use "../../../styles/variables" as v;
@import "../../../styles/breakpoints";

.inactive {
  border-radius: 80px;
  border: 1px solid rgba(15, 19, 34, 0.08);
  background: v.$btn-primary-bg;
  color: #858d98;
  display: inline-flex;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  font-weight: 600;
  column-gap: 8px;
  min-width: 60px;
  height: 30px;
  min-width: 60px;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}
.secondary-active {
  border-radius: 80px;
  border: 1px solid #101828;
  background: #101828 !important;
  color: #fff;
  display: inline-flex;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  font-weight: 600;
  min-width: 60px;
  height: 30px;

  column-gap: 8px;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}
.primary {
  border-radius: 80px;
  border: 1.5px solid;
  background: v.$btn-primary-bg;
  color: #000;
  display: inline-flex;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  font-weight: 600;
  min-width: 60px;
  height: 30px;

  column-gap: 8px;
  flex-shrink: 0;
  transition: 0.3s ease-in-out;

  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}
.primary:hover {
  border: 1.5px solid v.$color-border-btn;
  background: v.$btn-active-bg;
  color: v.$primary-text;
}
.primary-active {
  border-radius: 80px;
  border: 1.5px solid v.$color-border-btn;
  background: v.$btn-active-bg;
  color: v.$primary-text;
  display: inline-flex;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  min-width: 60px;
  height: 30px;

  font-weight: 700;
  column-gap: 8px;
  flex-shrink: 0;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}
.primary-active-fullWidth {
  border-radius: 80px;
  border: 1.5px solid v.$color-border-btn;
  background: v.$btn-active-bg;
  color: v.$primary-text;
  display: inline-flex;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  min-width: 60px;
  height: 30px;
  font-weight: 700;
  width: 100%;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}

.secondary-active-fullWidth {
  border-radius: 80px;
  border: 1px solid #101828;
  background: #101828 !important;
  color: #fff;
  display: inline-flex;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  font-weight: 600;
  min-width: 60px;
  height: 30px;
  width: 100%;
  column-gap: 8px;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}

.inactive-fullWidth {
  border-radius: 80px;
  border: 1px solid rgba(15, 19, 34, 0.08);
  background: v.$btn-primary-bg;
  color: #858d98;
  display: inline-flex;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  min-width: 60px;
  height: 30px;
  cursor: pointer;
  font-weight: 600;
  column-gap: 8px;
  width: 100%;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}

.delete-button {
  border-radius: 80px;
  border: 1.5px solid #dc2d2d;
  background: v.$btn-primary-bg;
  color: #dc2d2d;
  display: inline-flex;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  min-width: 60px;
  height: 30px;

  font-weight: 600;
  column-gap: 8px;
  width: 145px;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}
.delete-button-floating {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  border-radius: 80px;
  border: 1.5px solid #dc2d2d;
  background: v.$btn-primary-bg;
  color: #dc2d2d;
  display: inline-flex;
  padding: 15px 20px;
  justify-content: center;
  min-width: 60px;
  height: 30px;

  align-items: center;
  font-size: 13px;
  cursor: pointer;
  font-weight: 600;
  column-gap: 8px;
  width: 145px;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}

.secondary-active-floating {
  position: fixed;
  bottom: 20px;
  right: 40px;
  display: none;
  border-radius: 80px;
  border: 1px solid #101828;
  background: #101828;
  color: #fff;
  display: inline-flex;
  padding: 15px 20px;
  min-width: 60px;
  height: 30px;

  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  font-weight: 600;
  column-gap: 8px;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}
