@import "../../../styles/breakpoints";
.noDataDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);

  width: 100%;
  // overflow-y: auto;

  height: 100%;
  @include max-md {
    width: 100%;
    padding: 0;
  }
}
.noData-Img {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  mix-blend-mode: luminosity;
}
.noData-title {
  color: #0f1322;
  text-align: center;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  margin-top: 20px;
}
.noData-text {
  color: #0f1322;
  text-align: center;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  margin: 20px;
}
