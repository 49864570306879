@import "../../../styles/breakpoints";

.NotificationCard {
  display: flex;
  padding: 24px 0;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  height: 84px;
  padding: 20px 30px;
  cursor: pointer;

  @include max-md {
    padding: 16px;
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.NotificationCard-left {
  display: flex;
  align-items: center;
  column-gap: 16px;
  @include max-md {
    align-items: flex-start;
  }
}

.title {
  color: #101828;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    color: #101828;
    font-size: 10px;
    line-height: 16px;
  }
}

.date {
  color: #858d98;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 8px;
    line-height: 14px;
  }
}
