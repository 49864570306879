.datePicker {
  border-radius: 5px;
  width: 100%;
  height: 35px;
  font-size:12px;
  border: 1px #0c8af8 solid;
  padding: 4px 12px;
  background-color: white;
  box-shadow: 0 0 2px #0074d9;
}

.datePicker:focus {
  outline: none;
  border: 1px solid #0c8af8;
  box-shadow: 0 0 10px 2px #0074d9;
}


.filter-container{
  // mt-4 mr-10 ml-10 flex
  display: grid;
  // 3 columns have the same width calculated by the grid
  grid-template-columns: repeat(3,calc(100% / 3.1));
  margin: 8px 16px 0px 16px;
  align-items: center;
  column-gap: 10px;
  row-gap: 10px;
}