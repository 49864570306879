@import "../../../styles/breakpoints";

.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  // border: 2px solid rgba(157, 164, 173, 0.2);
  // background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(44, 50, 169, 0.04);
  padding: 16px;
  overflow-y: auto;

  .min-height-80vh {
    min-height: fit-content !important;
    max-height: fit-content !important;
  }

  .modal-content {
    // max-height: 80vh;
    margin: auto;
    padding: 16px;
    background: #fff;
    border-radius: 24px;
    overflow-y: auto;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
    @include max-sm {
      padding: 0;
      width: 100%;
    }
  }
}
