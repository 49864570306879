@import "../../../styles/breakpoints";

.AddSchedule {
  display: flex;
  flex-direction: column;
  background: #fff;
  // height: 628px;
  width: 600px;
  // padding: 40px;
  align-items: center;

  @include max-md {
    padding: 24px 20px;
    width: auto;
    height: auto;
  }

}
.AddSchedule-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;

  @include max-md {
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.AddSchedule-main-body {
  display: flex;
  flex-direction: column;
  width: 95%;
  row-gap: 27px;

  @include max-md {
    row-gap: 12px;
    // display: flex;
    // flex-direction: column-reverse;
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.AddSchedule-main {
  display: flex;
  align-items: center;
  justify-content: space-between;

  // margin-top: 25px;
  // margin-bottom: 24px;
  @include max-md {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 0;
  }
  @include max-sm {
    // margin-top: 60px;
  }
}

.date {
  color: #858d98;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 8px;
    line-height: 14px;
  }
}

.AddSchedule-selectField {
  display: flex;
  margin-top: 23px;
  border-bottom: 1px solid #c9d5ff;
  width: 67%;
  margin: auto;
  justify-content: center;
  @include max-md {
    border-bottom: none;
  }
}

.AddSchedule-dates {
  width: 95%;
}

.AddSchedule-eachSection {
  // margin-top: 23px;
  // border-bottom: 1px solid #c9d5ff;
  padding-top: 24px;
  // padding-bottom: 38px;
  @include max-md {
    padding-top: 0;
  }
}

.eachSection-main {
  display: flex;
  justify-content: space-between;
  @include max-md {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.select-field {
  display: flex;
  column-gap: 16px;
  color: #000;
  // 
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 37px;
  padding-bottom: 4px;

  @include max-md {
    font-size: 10px;
    column-gap: 2px;
  }
}

.title {
  color: #101828;
  // 
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;

  @include max-md {
    font-size: 20px;
    line-height: 28px;
  }
}

.subTitle {
  color: #75808f;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 52px;
  @include max-md {
    font-size: 10px;
    line-height: 14px;
    margin-top: 14px;
    margin-bottom: 14px;
  }
}

.section-title {
  color: #101828;
  // 
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.AddSchedule-icon {
  display: flex;
  width: 80px;
  height: 80px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 160px;
  background: rgba(201, 213, 255, 0.3);
}

.inputField {
 height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    height: 38px;
    width: 100%;
    // height: 38px;
  }
}

.inputField-wrap {
  column-gap: 10px;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  align-items: center;
  padding-left: 24px;
  width: 436px;

  @include max-slg {
    // margin-top: 20px;
  }
  @include max-md {
    width: 100%;
    // margin-top: 38px;
  }
}

.inputField-wrap-time {
  column-gap: 10px;
  border-radius: 80px !important;
  border: 1.5px solid rgba(15, 19, 34, 0.08) !important;
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  align-items: center;
  padding-left: 12px;
  width: 90px;
  height:56px;
  @include max-xl {
    height: 40px;
  }
  @include max-lg {
    height: 40px;
  }
}

.textArea {
  // height: 332px;
  padding: 24px 28px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1.5px solid rgba(201, 213, 255, 0.4);
  background: #fff;
  margin-top: 24px;
  margin-bottom: 32px;
  width: 100%;
  height: 330px;
  @include max-md {
    height: 176px;
  }
}
.textArea:focus {
  border: 1.5px solid rgba(201, 213, 255, 0.4);
}

.buttonDiv {
  display: flex;
  column-gap: 16px;
}

.AddSchedule-buttons {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.heading {
  color: #000;
  // 
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  @include max-md {
    font-size: 10px;
    line-height: 26px;
    margin-left: 6px;
    font-weight: 400;
  }
}

.datePicker-field {
  border-radius: 80px;
  border: 1.5px solid rgba(201, 213, 255, 0.4);
  background: #fff;
  width: 436px;
  height: 44px;
  @include max-md {
    width: 100%;
  }
}

.datePicker-field > div {
  @include max-md {
    width: 92%;
  }
}

.datePicker-field > div > div > div > input {
  margin-left: 12px;
  border: none !important;
  width: 408px;
  height: 39px;
  padding: 7px 8px;
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    width: 100%;
  }
}

.eachSection-dates-availability {
  display: flex;
  align-items: center;
  column-gap: 26px;
  justify-content: space-between;
  @include max-md {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.eachSection-dates {
  display: flex;
  row-gap: 23px;
  flex-direction: column;
  @include max-md {
    row-gap: 5px;
  }
}

.selectInputAvailability {
  padding: 12px 16px 12px 20px;
  height: 56px !important;
  display: flex;
  width: 275px;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  align-items: center;
  @include max-md {
    width: 100%;
    height: 40px !important;
    padding: 4px 16px 4px 20px;
  }
}

.selectDiv {
  width:77%;
  @include max-md {
    width: 100%;
  }
}

.selectDiv-timeFrame {
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 434px;
  padding-left: 12px;
  @include max-md {
    justify-content: space-between;
    width: 100%;
  padding-left: 0px;

  }
}
.label {
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 10px;
  }
}

.border {
  border-bottom: 1px solid #c9d5ff;
  width: 70%;
  margin: auto;
  margin-top: 38px;
  @include max-md {
    margin-top: 10px;
  }
}

.input-checkbox {
  @include max-md {
    width: 12px;
    height: 12px;
  }
}
.input-checkbox:checked {
  @include max-md {
    width: 12px;
    height: 12px;
  }
}

.saveButton {
  width: 98px;
  height: 40px;
  border-radius: 80px;
  background: #c9d5ff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #101828;
  text-align: center;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  @include max-md {
    width: 90px;
    height: 25px;
    text-transform: uppercase;
  }
}
.cancelButton {
  width: 98px;
  height: 40px;
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #9fb5ff;
  text-align: center;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  @include max-md {
    width: 90px;
    height: 25px;
    text-transform: uppercase;
  }
}
