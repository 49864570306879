@import "../../../../../../../styles/breakpoints";

.RatingCard {
  display: inline-flex;
  width: 28%;
  height: 212.703px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 19.013px;
  background: #fff;
  box-shadow: 31.68752670288086px 22.181270599365234px 63.37505340576172px 0px
      rgba(0, 0, 0, 0.08),
    -6.337505340576172px -3.168752670288086px 25.350021362304688px 0px
      rgba(0, 0, 0, 0.08);

  @include max-md {
    width: 30%;
    height: 80.458px;
  }
}
.RatingCard-top {
  display: flex;
  background: #101828;
  height: 60%;
  width: 100%;
  border-top-left-radius: 19.013px;
  border-top-right-radius: 19.013px;
  align-items: center;
  justify-content: center;
}
.RatingCard-bottom {
  background: #fff;
  height: 40%;
  display: flex;
  align-items: center;
  column-gap: 14px;
}

.ratingText {
  color: #fff;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 50.7px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-right: 8px;
  @include max-md {
    font-size: 19.178px;
  }
}

.ratingCard-img {
  width: 55.701px;
  height: 55.701px;
  object-fit: contain;
  @include max-md {
    width: 21.701px;
    height: 21.701px;
  }
}

.ratingText-title {
  color: var(--dark-grey, #6a6a6a);
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 28.519px;
  font-style: normal;
  font-weight: 700;
  line-height: 38.025px;
  @include max-md {
    font-size: 10.788px;
    line-height: 14.383px;
  }
}
.ratingText-subTitle {
  color: var(--dark-grey, #6a6a6a);
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 16 .259px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.013px;
  padding-top: 5px;
  @include max-md {
    font-size: 5.394px;
    line-height: 7.192px;
  }
}
