@import "../../../styles/breakpoints";

.inputField {
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 80px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  @include max-xl {
    height: 40px;
  }
  @include max-lg {
    height: 40px;
  }
}
.inputField-date {
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 80px;
  font-size: 14px;
  padding: 0px;
  padding-right: 10px;
  @include max-xl {
    height: 40px;
  }
  @include max-lg {
    height: 40px;
  }
}

.time-mobile{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.inputField-wrap {
  position: relative;
  width: auto;
}
.inputField-wrap-time {
  column-gap: 10px;
  border-radius: 80px !important;
  border: 1.5px solid rgba(15, 19, 34, 0.08) !important;
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  align-items: center;
  padding-left: 12px;
  width: 100%;
  height: 40px;
  @include max-xl {
    height: 40px;
  }
  @include max-lg {
    height: 40px;
  }
  @include max-sm {
    font-size: 12px;
  }
}
.inputField-wrap-time-error {
  column-gap: 10px;
  border-radius: 80px !important;
  border: 1.5px solid rgb(253, 71, 71) !important;
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(34, 15, 15, 0.04);
  display: flex;
  align-items: center;
  padding-left: 12px;
  width: 95%;
  height: 40px;
  @include max-xl {
    height: 40px;
  }
  @include max-lg {
    height: 40px;
  }
  @include max-sm {
    font-size: 12px;
  }
}

.prefix {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #0f1322;
}
.prefix-input {
  padding-left: 24px;
}

.inputField-body {
  display: flex;
  align-items: center;
}

.error-title {
  color: #ff0000;
}

.error-input {
  color: #ff0000;
  border: 1.5px solid #ff0000;
  // also apply color to the icon which is in the same div as the icons are svg
  svg {
    color: #ff0000;
  }
}

.fill {
  color: #ff0000;
}

.labelText {
  color: #0f1322;

  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 6px;
}
