

.react-datetimerange-picker__wrapper{
    column-gap: 10px !important;
    border-radius: 5px !important;
    border: 1.5px solid rgba(15, 19, 34, 0.08) !important;
    background: #fff !important;
    box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04) !important;
    display: flex !important;
    align-items: center !important;
    padding-left: 12px !important;
    width: 100% !important;
    height: 29px !important;
}

.react-datetimerange-picker__clock{
display: none !important;
}
.react-datetimerange-picker__calendar{
display: none !important;
}

.react-datetimerange-picker__button{
    display: none !important;
}