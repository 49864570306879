@import "../../../../../../styles/breakpoints";

.CompanyDetailsCard {
  // margin: 64px 32px;
  height: auto;
  flex-shrink: 0;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 20px 15px;

  @include max-md {
    padding: 24px 20px;
  }

}
.CompanyDetailsCard-head {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include max-md {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
}
.CompanyDetailsCard-head-left {
  display: flex;
  align-items: center;
  column-gap: 16px;

  @include max-sm {
    // margin: 64px 20px;
  }
}

.company-details-text {
  display: flex;
  align-items: center;
  column-gap: 16px;

  @include max-md {
    width: 100%;
    justify-content: space-between;
  }
}

.companyName {
  color: #101828;
   
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  @include max-md {
    font-size: 20px;
    line-height: 24px;
  }
}
.title {
  color: #101828;
   
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 16px;
  text-align: left;
  @include max-md {
    margin-top: 24px;
    font-size: 20px;
    line-height: 28px;
  }
}
.description {
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: justify;
  @include max-md {
    font-size: 12px;

    line-height: 20px;
  }
}

.companyLogo {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 50%;
  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  @include max-md {
    width: 48px;
   height: 48px;
  }
}

.reviews {
  display: flex;
  width: 118px;
  height: 32px;
  padding: 8px 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 32px;
  background: rgba(241, 245, 254, 0.5);
}

.reviewText {
  color: #1c53f4;
  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 145.455% */
  text-transform: capitalize;
  @include max-md {
    font-size: 9.625px;
    line-height: 14px;
  }
}

.ratingCard {
  width: 144px !important;
  height: 120px !important;
  @include max-md {
    width: 120px !important;
    height: 84px !important;
  }
}
.googleText {
  font-size: 16.089px !important;
  line-height: 21.453px !important;
  @include max-md {
    font-size: 11.323px !important;
    line-height: 15.097px !important;
  }
}
.ratingText {
  font-size: 28.603px !important;
  @include max-md {
    font-size: 20.129px !important;
  }
}
.logoClass {
  width: 31.425px !important;
  height: 31.425px !important;
  @include max-md {
    width: 22.114px !important;
    height: 22.114px !important;
  }
}

.starClass {
  font-size: 1em !important;
}

.subTitleClass {
  font-size: 8.045px !important;
  line-height: 10.726px !important;
}

.companyDetails {
  color: #101828;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
