.itemCard {
  display: flex;
  flex-direction: column;
  height: 119px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #fff;
  padding: 16px;
  // align-items: center;
  justify-content: center;
  min-width: 175px;
  text-align: left;
}

.itemCard-main {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.value {
  color: #000;

  // font-feature-settings: 'clig' off, 'liga' off;
  // font-family: Noto Sans JP;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
