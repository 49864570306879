.user-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.heading {
  color: var(--primary-dark-shade, #05004e);
  /* xl/Semibold */
  font-family: "Poppins-SemiBold";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}
.notificationDiv {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  align-items: center;
}

.notifications {
  max-height: 90%;
  overflow-y: auto;
  padding: 8px 0;
}

.image {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 50%;
}

.title {
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.time {
  color: var(--gray-400, #7a8699);
  font-feature-settings: "clig" off, "liga" off;
  // font-family: "Inter";
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
