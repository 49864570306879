// @import "../../../styles/breakpoints";

.inputField {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 80px;
  font-size: 11px;
  height: 40px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  // @include max-xl {
  //   height: 40px;
  // }
  // @include max-lg {
  //   height: 40px;
  // }
}
.inputField-date {
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 80px;
  font-size: 11px;
  height: 40px;
  padding: 0px;
  padding-right: 10px;
  // @include max-xl {
  //   height: 40px;
  // }
  // @include max-lg {
  //   height: 40px;
  // }
}

.inputField-wrap {
  column-gap: 10px;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  align-items: center;
  padding-left: 8px;
  // @include max-xl {
  // }
  // @include max-slg {
  // }
}
.inputField-wrap-password {
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  align-items: center;
  padding-left: 11px;
  padding-right: 0px;
}

.inputField-body {
  display: flex;
  align-items: center;
}

.error-title {
  color: #ff0000;
}

.error-input {
  border: 1.5px solid #ff0000;
  // also apply color to the icon which is in the same div as the icons are svg
}

.fill {
  color: #ff0000;
}

.labelText {
  color: #0f1322;

  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 6px;
}
