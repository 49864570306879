@import "../../../styles/breakpoints";

.day-view {
  display: flex;
}

.day-view-left {
  min-width: 64px;
}

.available-color{
  color:#009633;
}

.day-view-main {
  overflow-x: auto;
  width: 80%;
}
.day-view-main-full {
  overflow-x: auto;
  width: 100%;
}

.weekLabel {
  display: flex;
  height: 82px;
  align-items: center;
  overflow-x: auto;
  border-right: 2px solid #496ff8;
  border-bottom: 2px solid #496ff8;
  background-color: #c9d5ff;
}

.datesContainer-head {
  display: flex;
  width: 100%;
  height: 82px;
}

.datesContainer {
  display: flex;
  align-items: center;
  justify-items: center;
  min-height: 82px;
}



.datesContainer-events {
  display: flex;
  align-items: center;
  justify-items: center;
  min-height: 82px;
  // border-bottom: 1px solid rgba(59, 204, 65, 0.6);
}

.dayLabel {
  color: #75808f;
  text-align: center;
  //font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;

}

.dayLabel-day {
  color: #75808f;
  // text-align: center;
  //font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  width: 155px;
  height:100%;
  padding: 6px 4px;
  border-right: 2px solid #496ff8;
  border-bottom: 2px solid #496ff8;
  background-color: #c9d5ff;
}

.dateLabel {
  color: #858d98;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.dateContainer {
  padding: 6px 4px;
  min-width: 100px;
  max-width: 100px;
}

.eventsContainer {
  color: #858d98;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  height: 60px;
  position: relative;
}

.eventsContainer-body {
  min-height: 45px;
  padding: 1.75px 12px;
  border-radius: 6px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // max-width: 100%;
  width: auto;
  position: absolute;

  &-available {
    background-color: #e4faf2;
    border-left: 8px solid #16911b;
  }

  &-unavailable {
    background-color: #f3d4d4;
    border-left: 8px solid #da011c;
  }

  &-partially_available {
    background-color: #b5b8c3;
    border-left: 8px solid #181203;
  }

  &-already_hired {
    background-color: #c9d5ff;
    border-left: 8px solid #181203;
  }
}

.eventsContainer-body-popover-secondary {
  min-height: 45px;
  padding: 1.75px 12px;
  border-radius: 6px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 220px;

  &-available {
    background-color: #e4faf2;
    border-left: 8px solid #16911b;
  }

  &-unavailable {
    background-color: #f3d4d4;
    border-left: 8px solid #da011c;
  }

  &-partially_available {
    background-color: #b5b8c3;
    border-left: 8px solid #181203;
  }

  &-already_hired {
    background-color: #c9d5ff;
    border-left: 8px solid #181203;
  }
}

.eventsContainer-body-popover {
  min-height: 45px;
  padding: 1.75px 12px;
  border-radius: 6px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 220px;

  &-available {
    background-color: #e4faf2;
  }

  &-unavailable {
    background-color: #f3d4d4;
  }

  &-partially_available {
    background-color: #b5b8c3;
  }

  &-already_hired {
    background-color: #c9d5ff;
  }
}



.eventTitle {
  //font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 6px;
  max-width: 115px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &-available {
    color: #16911b;
  }

  &-unavailable {
    color: #da011c;
  }

  &-partially_available {
    color: #181203;
  }

  &-already_hired {
    color: #181203;
  }
}

.companyDetail {
  //font-family: Montserrat;
  font-size: 8px;
  font-weight: 500;

  &-available {
    color: #16911b;
  }

  &-unavailable {
    color: #da011c;
  }

  &-partially_available {
    color: #181203;
  }

  &-already_hired {
    color: #181203;
  }
}

.eventTime {
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-size: 7px;
  font-weight: 400;
  line-height: 16px;

  &-available {
    color: #16911b;
  }

  &-unavailable {
    color: #da011c;
  }

  &-partially_available {
    color: #181203;
  }

  &-already_hired {
    color: #181203;
  }
}

.redBackground {
  background-color: red;
}

.greenBackground {
  background-color: green;
}

.grayBackground {
  background-color: lightgray;
}

.companyData-top {
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
  align-items: center;
}

.chevronDownIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.eventDiv-right {
  cursor: pointer;
  margin-left: 12px;
}

.actionButton {
  cursor: pointer;
}

.datesContainer-bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 64px;
  padding: 6px 16px;
  @include max-md{
    justify-content: flex-start;
  }
}

.indicators {
  display: flex;
  column-gap: 20px;
  @include max-md{
    flex-direction: column;
    justify-content: flex-start;
  }
}
.each-indicator {
  display: flex;
  align-items: center;
  column-gap: 18px;
}
.labelText {
  color: #0f1322;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.eventTitle-popover {
  //font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 6px;
  // line-height: 16px;

  &-available {
    color: #16911b;
    // background-color: #e4faf2;
  }

  &-unavailable {
    color: #da011c;
    // background-color: #f3d4d4;
  }

  &-partially_available {
    color: #181203;
    // background-color: #b5b8c3;
  }

  &-already_hired {
    color: #181203;
    // background-color: #c9d5ff;
  }
}

.calander-detail {
  width: 450px;
  height: 100%;
  padding: 10px;
  padding-left: 5px;;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: 1000;

  @include max-lg {
    width: 100%;
    border-left: none;
    border-top: #75808f 1px solid;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: #ffffff;
    top: 0;
    justify-content: center;
  }
}
.title {
  color: #101828;
  // 
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 177.778% */
}

.inactive-btn {
  border-radius: 36px;
  border: 1px solid #d6dce6;
  background: #c9d5ff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  width: 110px;
  height: 32px;
  padding: 6px 10px 6px 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  color: #101828;
  font-family: Open Sans;
  font-size: 12.13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 164.88% */
}
.active-btn {
  border-radius: 36px;
  border: 1px solid #101828;
  background: #101828;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  color: #ffffff;
  display: flex;
  width: 110px;
  height: 32px;
  padding: 6px 10px 6px 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  font-family: Open Sans;
  font-size: 12.13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 164.88% */
}

.btn-tabs {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.header-btn {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.header-back-btn {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: flex-start;
  align-items: center;
}

.panel-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  border: #d6dce6 solid 1px;
  padding: 10px;
}

.panel-item {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  align-items: center;
}

.icon {
  justify-content: flex-start;
  width: 35px;
}

.time-between {
  color: #101828;
  font-family: Open Sans;
  font-size: 12.134px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.334px;
  width:100px;
}
.description {
  color: #101828;
  font-family: Open Sans;
  font-size: 12.134px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.334px;
  width: calc(100% - 10px);
}

.panelbody {
  height: 400px;
  overflow: hidden;
  overflow-y: scroll;
}

.btn-accept {
  text-decoration: underline;
  color: #101828;
  font-family: Open Sans;
  font-size: 8px;width:100px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  margin: 2px;
}
.btn-decline {
  text-decoration: underline;
  // decline color
  color: #da011c;
  font-family: Open Sans;
  font-size: 8px;width:100px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  margin: 2px;
}


.eventsContainer-body-head {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // margin-bottom: 16px;
  // margin-top: 10px;
  column-gap: 12px;
}

.action-button > svg {
  cursor: pointer;
}


.inProgress {
  display: inline-flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  color: #fff;

  border-radius: 8px;
  background: #101828;
  cursor: pointer;
  font-family: Open Sans;
  font-size: 8px;width:100px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;

  @include max-md {
    font-size: 11px;
    padding: 4px 8px;
  }
  @include max-xs {
    font-size: 9px;
    padding: 4px 8px;
  }
}



.completed {
  display: inline-flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  color: #fff;

  border-radius: 8px;
  background: #9fb5ff;
  cursor: pointer;


  font-family: Open Sans;
  font-size: 8px;width:100px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;

  @include max-md {
    font-size: 11px;
    padding: 4px 8px;
  }
  @include max-xs {
    font-size: 9px;
    padding: 4px 8px;
  }
}
.declined {
  display: inline-flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  color: #fff;

  border-radius: 8px;
  background: rgb(228, 174, 38);
  cursor: pointer;

  font-family: Open Sans;
  font-size: 8px;width:100px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;

  @include max-md {
    font-size: 11px;
    padding: 4px 8px;
  }
  @include max-xs {
    font-size: 9px;
    padding: 4px 8px;
  }
}
.rejected {
  display: inline-flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  color: #fff;

  border-radius: 8px;
  background: #f44336;
  cursor: pointer;

  font-family: Open Sans;
  font-size: 8px;width:100px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;

  @include max-md {
    font-size: 11px;
    padding: 4px 8px;
  }
  @include max-xs {
    font-size: 9px;
    padding: 4px 8px;
  }
}
.approved {
  display: inline-flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  color: #fff;

  border-radius: 8px;
  // light green background color
  background: #7bf1aa;
  cursor: pointer;

  font-family: Open Sans;
  font-size: 8px;width:100px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;

  @include max-md {
    font-size: 11px;
    padding: 4px 8px;
  }
  @include max-xs {
    font-size: 9px;
    padding: 4px 8px;
  }
}

.paused {
  display: inline-flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  color: #fff;

  border-radius: 8px;
  background: #f44336;
  cursor: pointer;

  font-family: Open Sans;
  font-size: 8px;width:100px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;

  @include max-md {
    font-size: 11px;
    padding: 4px 8px;
  }
  @include max-xs {
    font-size: 9px;
    padding: 4px 8px;
  }
}