@import "../../../styles/breakpoints";

.JobsCompletedCard {
  border-radius: 20px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  padding: 26px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include max-sm {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
  }
  @include max-sm {
    // margin: 64px 20px;
  }
}

.title {
  color: #101828;
  // 
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 16px;
  @include max-md {
  }
}

.image {
  width: 48px;
 height: 40px;
  flex-shrink: 0;
  border-radius: 400px;
  @include max-md {
  }
}

.jobName {
  color: #101828;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  @include max-md {
    font-size: 16px;
    line-height: 28px;
  }
}

.companyName {
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 12px;
  }
}

.earn {
  color: #1c53f4;
  text-align: right;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  @include max-md {
    font-size: 20px;

    line-height: 28px;
  }
}

.hoursWorked {
  color: #101828;
  text-align: right;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 12px;

    line-height: 20px;
  }
}

.JobsCompletedCard-left {
  display: flex;
  column-gap: 12px;
}

.JobsCompletedCard-right {
  @include max-sm {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: auto;
    margin-top: 12px;
  }
}
