.text {
  font-weight: 400;
  font-family: "Open Sans";
}
// .bold {
//   font-weight: bold;
// }

.text {
  &-gray {
    color: #737791;
  }
  &-black {
    color: black;
  }
  &-yellow {
    color: yellow;
  }
  &-white {
    color: white;
  }
  &-pink {
    color: pink;
  }
  &-xxl {
    font-size: 64px;
  }
  &-xl {
    font-size: 48px;
  }
  &-36 {
    font-size: 36px;
  }
  &-l {
    font-size: 24px;
  }
  &-m {
    font-size: 20px;
  }
  &-s {
    font-size: 16px;
  }
  &-xs {
    font-size: 14px;
  }
  &-xxs {
    font-size: 12px;
  }
  &-10 {
    font-size: 10px;
  }
  &-true {
    font-weight: 700;
  }
  &-400 {
    font-weight: 400;
  }
  &-500 {
    font-weight: 500;
  }
  &-700 {
    font-weight: 700;
  }
}
.cardHeading {
  color: #000;

  font-feature-settings: "clig" off, "liga" off;
  font-family: Noto Sans JP;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.heading {
  color: var(--greys-blue-grey-900, #151d48);
  font-family: Poppins;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 140%; /* 33.6px */
}

// .white {
//   color: white;
// }
// .pink {
//   color: pink;
// }
