@import "../../../styles/breakpoints";

.HiredWorkerCard {
  width: 320px;
  height: auto;
  flex-shrink: 0;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  @include max-sm {
    padding: 16px;
    width: 100%;
    height: auto;
  }
}

.title-job {
  color: #101828;
  //  
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 16px;
  text-align: left;
}

.HiredWorkerCard-top {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // align-items: center;
}

.HiredWorkerCard-top-above {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HiredWorkerCard-top-below {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HiredWorkerCard-top-below-left {
  display: flex;
  column-gap: 8px;
  align-items: center;
}

.HiredWorkerCard-mid-top {
  display: flex;
  column-gap: 8px;
}

.HiredWorkerCard-top-left {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.HiredWorkerCard-mid {
  display: flex;
  // height: 70%;
  flex-direction: column;
  justify-content: space-evenly;
}

.HiredWorkerCard-mid-details {
  display: flex;
  column-gap: 4px;
  align-items: center;
}

.HiredWorkerCard-mid-bottom {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.HiredWorkerCard-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HiredWorkerCard-bottom-left {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
.promoted {
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #9fb5ff;
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  @include max-slg {
    font-size: 12px;
  }
  @include max-md {
    font-size: 11.325px;
    line-height: 16.179px; /* 142.857% */
    letter-spacing: 0.68px;
  }
}

.cateogory {
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #101828;
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  @include max-slg {
    font-size: 12px;
  }
  @include max-md {
    font-size: 10.719px;
    line-height: 15.312px;
    letter-spacing: 0.643px;
  }
}

.title {
  color: #101828;
  //  
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  @include max-slg {
    font-size: 26px;
    line-height: 38px;
  }
  @include max-md {
    font-size: 24.5px;
    font-weight: 600;
    line-height: 36.75px;
  }
}

.details {
  color: #75808f;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 10.719px;
  }
}

.companyName {
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  @include max-md {
    font-size: 14px;
  }
}

.rate {
  color: #1c53f4;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.userLogo {
  width: 48px;
  height: 48px;
  border-radius: 64px;
  flex-shrink: 0;
  object-fit: cover;
  @include max-slg {
    width: 40px;
    height: 40px;
  }
  @include max-md {
    width: 36.75px;
    height: 36.75px;
  }
}

.proposalSubmitted {
  color: #05cf69;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 11.325px;
    line-height: 16.179px;
  }
}

.invitedImage {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  border-radius: 28px;
  object-fit: cover;
  @include max-md {
    width: 22.651px;
    height: 22.651px;
  }
}

.invitedText {
  color: #75808f;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  column-gap: 6px;
  @include max-md {
    font-size: 9.708px;
    line-height: 14.561px;
  }
}

.invitedName {
  color: #101828;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  @include max-md {
    font-size: 9.708px;
    line-height: 14.561px;
  }
}

.invitedDiv {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  align-items: center;
}

.skill {
  display: flex;
  width: max-content;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1.5px solid rgba(201, 213, 255, 0.4);

  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  @include max-md {
  }
}

.HiredWorkerCard-mid-skills {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 8px;
  @include max-md {
  }
}

.HiredWorkerCard-top {
  border-bottom: 1px solid rgba(28, 83, 244, 0.06);
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.HiredWorkerCard-bottom {
  border-top: 1px solid rgba(28, 83, 244, 0.06);
  padding-top: 10px;
}

.topRated-div {
  border-radius: 8px;
  background: #101828;
  padding: 6px 12px;
  justify-content: center;
  display: flex;
  column-gap: 6px;
}
.topRated-text {
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
}

.socialIcon-div {
  border-radius: 80px;
  background: rgba(201, 213, 255, 0.2);
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
}

.available {
  border-radius: 8px;
  background: #e7ffe1;
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  color: #26c700;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.hired {
  border-radius: 8px;
  background: #101828;
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.HiredWorkerCard-mid {
  // margin: 24px 0;
}

.description {
  color: #75808f;
  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  @include max-sm {
    font-size: 12px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
}

.description-value {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: #101828;
  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.details-div {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 15px;
  padding: 10px 0;
}

.linearProgress {
  background-color: #f5f7ff !important;
  // color: yellow !important;
}

.linearProgress > span {
  background-color: #ffbd00 !important;
}

.details-task-div {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-top: 1.5px solid rgba(201, 213, 255, 0.4);
  border-bottom: 1.5px solid rgba(201, 213, 255, 0.4);
}

.details-task-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.joiningDate-date {
  display: flex;
  column-gap: 8px;
  margin-top: 8px;
}
