@import "../../styles/breakpoints";

.OrganizationJobsPage {
  flex-shrink: 0;
  // background: #fff;
  // box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  // height: calc(100vh - 250px);
  height: calc(100%);
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  // padding: 48px;
  @include max-md {
    // padding: 32px;
  }
  @include max-sm {
    // padding: 16px;
  }
}

.OrganizationJobsPage-main {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  height: 100%;
  column-gap: 48px;
  @include max-md {
    display: flex;
    flex-direction: column;
    // padding: 16px 0;
  }
  @include max-sm {
    // margin-top: 60px;
  }
}
.OrganizationJobsPage-right {
  width: 70%;
  // overflow-y: auto;

  height: 100%;

  @include max-slg {
    width: 63%;
    padding: 32px 16px;
  }
  @include max-md {
    width: 100%;
    padding: 0;
  }
}

.OrganizationJobsPage-jobs {
  // height: 100%;
  // height: 90%;
  // overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  column-gap: 44px;
  row-gap: 44px;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    display: none;
  }
  @include max-md {
    width: 100%;
  }
}

.OrganizationJobsPage-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 44px;
  @include max-md {
    display: flex;
    flex-direction: column;
  }
}

.OrganizationJobsPage-top-left {
  display: flex;
  column-gap: 16px;
  // flex-wrap: wrap;
  row-gap: 16px;
  justify-content: center;
  margin: 16px 0;
  max-width: 70%;
}

.title {
  color: #101828;
  //  
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  @include max-md {
    font-size: 24px;
    line-height: 32px;
  }
}

.inputField {
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
}

.inputField-wrap {
  column-gap: 10px;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  align-items: center;
  padding-left: 24px;
}

.OrganizationJobsPage-right-top-left {
  display: flex;
  column-gap: 16px;
  @include max-slg {
    column-gap: 0;
  }
  @include max-md {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 12px;
    margin-bottom: 18px;
  }
}

.OrganizationJobsPage-right-jobs {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 40px;

  @include max-md {
    // justify-content: center;
    row-gap: 24px;
  }
  @include max-sm {
    justify-content: center;
  }
}

.selectInput {
  padding: 12px 16px 12px 20px;
  height: 48px !important;
  display: flex;
  width: 240px;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  align-items: center;
  @include max-slg {
    width: auto;
  }
}

.selectInput__menu {
  /* Custom styles for the options container */
  border: 2px solid #ccc;
  border-radius: 50px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.jobs-date {
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.dateDiv {
  display: flex;
  column-gap: 12px;
  align-items: center;
  margin: 32px 0;
}

.filterBtn {
  display: none;
  width: 44px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 80px;
  border: 1.5px solid rgba(201, 213, 255, 0.4);
  background: #fff;
  @include max-md {
    display: flex;
  }
}

.inputDiv-searchDiv {
  display: flex;
  justify-content: space-between;
  @include max-slg {
    width: 45%;
  }
  @include max-md {
    width: 100%;
  }
}

.inputDiv-search {
  // width: 86%;
}
.inputDiv {
  @include max-md {
    width: 55%;
  }
}

.activeButton {
  border-radius: 80px !important;
  background: #101828 !important;
  height: 40px !important;
  padding: 10px 20px !important;
  justify-content: center !important;
  align-items: center !important;
  flex-shrink: 0 !important;
  color: #fff !important;
  font-family: Open Sans !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-transform: capitalize !important;
}

.inactiveButton {
  border: none !important;
  border-radius: 80px !important;
  background: #fff !important;
  height: 40px !important;
  padding: 10px 20px !important;
  justify-content: center !important;
  align-items: center !important;
  flex-shrink: 0 !important;
  color: #9fb5ff !important;
  font-family: Open Sans !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-transform: capitalize !important;
  margin-left: 0 !important;
}

.toggleTabs {
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #fff;
  display: flex;
  width: 237px;
  height: 40px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
}

.workerClass {
  height: auto !important;
}

.midSectionClass {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0 !important;
}

.primaryBtn {
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #101828;
  display: inline-flex;
  padding: 14px 28px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  flex-shrink: 0;
  @include max-md {
    padding: 8px 16px;
    font-size: 12px;
    line-height: 16px;
  }
}
.secondaryBtn {
  display: inline-flex;
  padding: 14px 28px;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  border: 1.5px solid #c9d5ff;
  background: #fff;
  color: #9fb5ff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  flex-shrink: 0;
  @include max-md {
    padding: 8px 16px;
    font-size: 12px;
    line-height: 16px;
  }
}

.OrganizationJobsPage-top-right {
  @include max-md {
    width: 100%;
  }
}
