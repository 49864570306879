@import "../../../styles/breakpoints";
.noDataDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.noData-Img {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  mix-blend-mode: luminosity;
}
.noData-text {
  color: #858d98;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 40px;
}