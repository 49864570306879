@import "../../../styles/breakpoints";

.select-container {
  display: flex;
  width: 100%;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  align-items: center;
  position: relative;
  // height: 40px;
  // @include max-xl {
  //  height: 40px;
  // }
  // @include max-lg {
  //   height: 40px;
  // }
}
.select-container-less-width {
  padding-left: 12px;
  display: flex;
  width: 100%;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  align-items: center;
  height: 56px;
  @include max-xl {
   height: 40px;
  }
  @include max-lg {
    height: 40px;
  }
}

.sort-filter{
  position: absolute;
  right: 50px;
  top:10px;
  z-index: 200;;
}

.select-less-width {
  border: 0;
  outline: none !important;
  width: 100%;
  color: #0f1322;
  // color: #858d98;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  height: 100%;
}

.section-branchAndCategory {
  display: flex;
  // row-gap: 24px;
  flex-direction: column;
  border-radius: 24px;
  border: 2px solid #e7f1f8;
  background: #fff;
  padding: 40px 48px;
  margin-top: 32px;
  row-gap: 40px;
  @include max-md {
    padding: 20px 16px;
    row-gap: 20px;
  }
  @include max-sm {
    padding: 20px 16px;
  }
}

.selectDiv {
  width: 100%;
}
.icon-container {
  position: absolute;
  left: 10px;
  z-index: 100;
}

.attributesDiv {
  display: flex;
  column-gap: 16px;
  row-gap: 10px;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-bottom: 12px;
}
.deleteAttribute {
  position: absolute;
  top: -4px;
  right: -6px;
}

.attribute {
 height: 40px;
  flex-shrink: 0;
  border-radius: 80px;
  background: rgba(201, 213, 255, 0.3);
  color: #1c53f4;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  width: max-content;
  align-items: center;
  padding: 14px 28px;
  position: relative;
  @include max-md {
    font-size: 12px;
    line-height: 20px;
    height: 40px;
    padding: 10px 20px;
  }
}

.groupStyles{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.groupLabel{
  color: #0f1322;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  @include max-sm {
    font-size: 12px;
    line-height: 20px;
  }
}

.groupBadge{
  color: #858d98;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-sm {
    font-size: 10px;
    line-height: 20px;
  }
}