.card {
  background-color: white;
  border-radius: 16px;
  width: 500px;
  height: 650px;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  // bg-white rounded-2xl w-[345px] lg:w-[500px] lg:h-[650px] lg:space-y-5 lg:px-10 relative flex items-center cardReponsive

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem 0.5rem;

  // justify-center flex px-2 flex-col py-10 w-full
}

.dashboard-top {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 0 2rem;
}
.dashboard-top-left {
  width: 100%;
  padding: 18px;
  display: flex;
  column-gap: 32px;
  border-radius: 20px;
  border: 1px solid #f8f9fa;
  background: rgba(201, 213, 255, 0.4);
  box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5);
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  row-gap: 16px;
}

// .dashboard-top-right {
//   padding: 0 16px;
//   width: 22%;
//   display: flex;
//   flex-direction: column;
//   border-radius: 12px;
//   background: #9fb5ff;
//   box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12),
//     0px 0px 2px 0px rgba(145, 158, 171, 0.2);
//   height: 119px;
//   flex-shrink: 0;
//   align-items: center;
//   justify-content: space-around;
// }

.dashboard-top-right-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.percentage {
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 600px) {
  .card {
    width: 345px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 500px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card {
    width: 345px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 500px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card {
    width: 345px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 500px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card {
    width: 500px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    height: 650px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .card {
    width: 500px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    height: 650px;
  }
}
