@import "../../../../styles/breakpoints";

.JobDetailsOrganization {
  display: flex;

  justify-content: space-between;

  @include max-md {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  @include max-sm {
    // padding: 16px;
  }
}
.JobDetailsOrganization-left {
  display: flex;
  width: 67%;
  row-gap: 40px;
  flex-direction: column;

  @include max-md {
    // padding: 32px;
  }
  @include max-sm {
    // padding: 16px;
  }
}
.JobDetailsOrganization-right {
  display: flex;
  width: 30%;
  row-gap: 40px;
  flex-direction: column;

  @include max-md {
    // padding: 32px;
  }
  @include max-sm {
    // padding: 16px;
  }
}

.dateDiv {
  display: flex;
  // flex-direction: column;
  column-gap: 12px;
  margin-bottom: 34px;
}
.JobDetailsOrganization-right-similarJobs {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.jobCard {
  width: auto !important;
}

.jobs-date {
  color: #101828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
