

.slide{
  &-pdf{
    width: 70px;
    height: 70px;
    justify-content: center;
    align-items: center;
    display: flex;
    position:'relative'
  }

  &-image{
    width:300px;
    height:150px;
  }
}

.title {
  color: #0f1322;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.Image-styling{
  width: 300px;
  height: 220px;
  object-fit: cover;
  border-radius: 12px;
}