@import "../../../styles/breakpoints";

.AddJobModal {
  display: flex;
  flex-direction: column;
  background: #fff;
  // height: 628px;
  // width: 520px;
  padding: 40px;
  align-items: center;
  width: auto;
  overflow-y: auto;

  @include max-md {
    padding: 24px 20px;
    width: auto;
    height: auto;
  }
  @include max-sm {
    // margin-top: 60px;
  }
}
.AddJobModal-main {
  width: 100%;
}
.AddJobModal-head {
  display: flex;
  column-gap: 16px;
  align-items: center;

  width: max-content;
  cursor: pointer;
  margin-bottom: 70px;
  width: 100%;
  justify-content: center;
}

.backText {
  color: #101828;

  text-align: right;
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.inputField {
 height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    height: 38px;
    width: 100%;
    // height: 38px;
  }
}

.inputField-wrap {
  column-gap: 10px;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  background: #fff;
  box-shadow: 0px 6px 32px 0px rgba(15, 19, 34, 0.04);
  display: flex;
  align-items: center;
  padding-left: 24px;
  width: 300px;

  @include max-slg {
    // margin-top: 20px;
  }
  @include max-md {
    width: 100%;
    // margin-top: 38px;
  }
}

.eachSection {
  display: flex;
  align-items: center;
  column-gap: 32px;
  justify-content: space-between;
}

.heading {
  color: #000;
 //
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  @include max-md {
    font-size: 10px;
    line-height: 26px;
    margin-left: 6px;
    font-weight: 400;
  }
}

.AddJobModal-sections {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  row-gap: 32px;
}

.submitButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
