@import "../../../../../../styles/breakpoints";

.HiringManagerCard {
  flex-shrink: 0;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
  // justify-content: space-between;
  padding: 15px;
  display: flex;
  flex-direction: column;
  @include max-sm {
    padding: 24px;
  }
}
.image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.days{
  color: #fff;
  background-color: blue;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border-radius: 50px;
  padding:2px;
  height: 20px;
  justify-content: center;
  align-items: center;
  display: flex;

}

.urgentHiring {
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #dc2d2d;
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  width: max-content;
  margin-bottom: 32px;
}

.normalHiring {
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #26c700;
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  width: max-content;
  margin-bottom: 32px;
}

.manager {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.manager-details {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.description {
  color: #75808f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  @include max-sm {
    font-size: 12.771px;
    line-height: 18.245px;
  }
}

.name {
  color: #101828;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  @include max-sm {
    font-size: 14.596px;
    line-height: 18.245px;
  }
}

.details-div {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 32px;
  row-gap: 32px;
  @include max-sm {
    row-gap: 28px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @include max-sm {
    row-gap: 14px;
  }
}

.lastItem {
  margin-top: 32px;
  @include max-sm {
    margin-top: 28px;
  }
}

.description-value {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: #101828;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  @include max-sm {
    font-size: 12px;
    line-height: 18.245px;
  }
}
.description-value-grid {
  display: grid;
  // max 3 columns
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 8px;
  align-items: center;
  column-gap: 8px;
  color: #101828;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  @include max-sm {
    font-size: 12px;
    line-height: 18.245px;
  }
}
