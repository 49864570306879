@import "../../../styles/breakpoints";


.selectDiv {
  @include max-md {
    width: 100%;
  }
}
.selectDiv {
  flex: 1;
}

.selectInputAvailability {
  padding: 3px 8px 3px 8px;
  min-height: 40px !important;
  display: flex;
  border-radius: 80px;
  border: 1.5px solid rgba(15, 19, 34, 0.08);
  align-items: center;
  width: 100%;
  width: 100%;
  @include max-md {
    width: auto;
    height: 40px !important;
  }
}

.selectInputAvailability > div {
  // background: #f0f3ff;
  // height: 40px !important;
  min-height: auto !important;
  @include max-md {
  }
}

.selectInputAvailability > div > div {
  height: auto;
  @include max-md {
  }
}

.selectInputAvailability > div > div > div {
  // padding: 4px !important;

  // @include max-md {
  color: #101828;
  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  // padding: 0 !important;
  // }
}
.selectInputAvailability > div > div > div > svg {
  @include max-md {
    width: 16px;
    height: 16px;
  }
}

.height-options{
  height: 50px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}