@use "../../../../../../styles/variables" as v;
@import "../../../../../../styles/breakpoints";

.CardWithActionButtons {
    /* min-height: 238px; */
    flex-shrink: 0;
    border-radius: 24px;
    background: #fff;
    box-shadow: 0px 16px 60px 0px rgba(201, 213, 255, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 20px 20px 15px;

  @include max-md {
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: auto;
  }
}

.CardWithActionButtons-left {
  display: flex;
  column-gap: 10px;
}
.CardWithActionButtons-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 16px;
  flex-shrink: 0;
  margin-left: 50px;


  @include max-md {
    align-items: center;
    margin-top: 24px;
    margin-left: 0;
  }
}

.pc-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include max-md {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.pc-mobile{
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  @include max-md {
    flex-direction: row;
    justify-content: space-between;
    margin-left: 0;
  }
}
.button-div {
  display: flex;
  column-gap: 16px;
  @include max-md{
    flex-direction: column;
    row-gap: 16px;
  }
}

.left-details-iconText {
  display: flex;
  column-gap: 8px;
  margin-top: 5px;
}

.left-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include max-md {
    row-gap: 14px;
  }
}

.left-details-top {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  @include max-md {
    row-gap: 2px;
  }
}

.left-details-bottom {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @include max-md {
    row-gap: 12px;
  }
}

.companyLogo {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.title {
  color: #101828;
   
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.companyName {
  color: #101828;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 10px;
}

.description {
  color: #75808f;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  @include max-md {
    font-size: 10px;
    line-height: 16px;
  }
}

.select-primary{
  border-radius: 80px;
  border: 1.5px solid;
  background: v.$btn-primary-bg;
  color: #000;
  display: inline-flex;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  font-weight: 600;
  min-width: 60px;
  height: 30px;

  column-gap: 8px;
  flex-shrink: 0;
  transition: 0.3s ease-in-out;

  @include max-md {
    font-size: 12px;
    line-height: 20px;
  }
}

.under_review {
  // background color should be yellow 
  background: #FFD700 !important;
  color: #000 !important;
}

.admin{
  background: #c9d5ff !important;
  color: #fff !important;
}

.users{
  background: green !important;
  color: #fff !important;
}
.deleted{
  background: red !important;
  color: #fff !important;
}