.tableHeading {
  font-weight: bold;
  background-color: #c9d5ff;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  align-items: center;
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center;

}
.tableHeading-white {
  font-weight: bold;
  background-color: white;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  border-bottom: 2px solid black;
}

/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
